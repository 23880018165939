import Randomstring from 'randomstring';
import { setResultToast } from '../store/reducer/ResultToast.slide';

const Util = {
    randomStr: function(number){
        return Randomstring.generate(number);
    },

    showResultToast(dispatch, rs){
        if(rs.status !== 201 && rs.status !== 200){
            dispatch(setResultToast({open:true, message: rs.data.errorMsg || rs.data, severity: 'error'}));
        }else{
            dispatch(setResultToast({open:true, message: rs.data.data || rs.data, severity: 'success'}));
        }
    },

    getFriendlySize(sizeInBytes){
        if(!sizeInBytes || sizeInBytes === null){
            return "";
        }
        if(sizeInBytes < 1024){
            return sizeInBytes + " bytes";
        }
        let size = sizeInBytes/1024;
        if(size < 1024){
            return size.toFixed(1) + " KB";
        }
        size = size/1024;
        return size.toFixed(1) + " MB";
    },
    getFileExtension(fileName){
        if(!fileName){
            return "";
        }
        let parts = fileName.split('.');
        if(parts.length === 1){
            return "File";
        }
        return parts.pop();
    },
    getTypeFromExtension(extension){
        if(!extension){
            return "";
        }
        let rs = extensionToFileTypeMapping[extension.toLowerCase()];
        if(!rs){
            return "document";
        }
        return rs;
    }
}

/*
3d, acrobat, audio, binary, code, compressed, document, drive, font, image, presentation, settings, spreadsheet, vector, video
*/
const extensionToFileTypeMapping = {
    'pptx': 'presentation',
    'ppt': 'presentation',
    'odp': 'presentation',
    'ods': 'spreadsheet',
    'xls': 'spreadsheet',
    'xlsx': 'spreadsheet',
    'xlsm': 'spreadsheet',
    'csv' : 'spreadsheet',
    'doc': 'document',
    'docx': 'document',
    'odt': 'document',
    'txt': 'document',
    'pdf': 'acrobat',
    'html' : 'code',
    'css' : 'code',
    'js' : 'code',
    'java' : 'code',
    'aspx' : 'code',
    'asp' : 'code',
    'dart' : 'code',
    'py' : 'code',
    'gif' : 'image',
    'jpg' : 'image',
    'jpeg ' : 'image',
    'jfif' : 'image',
    'pjpeg' : 'image',
    'pjp' : 'image',
    'png' : 'image',
    'svg' : 'image',
    'webp' : 'image',
    'bmp' : 'image',
    'ico' : 'image',
    'cur' : 'image',
    'tif': 'image',
    'tiff': 'image',
    'acc': 'audio',
    'mp3': 'audio',
    'wav': 'audio',
    'aiff': 'audio',
    'flac': 'audio',
    'm4a': 'audio',
    'ogg': 'audio',
    'aac': 'audio',
    'wma': 'audio',
    'ape': 'audio',
    'webm': 'video',
    'mkv': 'video',
    'flv': 'video',
    'vob': 'video',
    'ogv': 'video',
    'avi': 'video',
    'wmv': 'video',
    'rm': 'video',
    'amv': 'video',
    'mp4': 'video',
    'm4v': 'video',
    'svi': 'video',
    '3gp': 'video',
    'rar': 'compressed',
    'gzip': 'compressed',
    'zip': 'compressed',
    '7zip': 'compressed',
    'tar': 'compressed',
    'xz': 'compressed',
    'bz2': 'compressed',
    'gz': 'compressed',
    
}

export default Util;