import Constants from '../../general/Constants';
import axios from '../../general/RESTClient';

const PersonalInfoService = {
    
    getAllPersonalInfo: async function () {
        try{
            return await axios.get(Constants.personalInfo.getAllPersonalInfoAPI);
        }catch(error){
            return error.response;
        }
    },
    createPersonalInfo: async function(data){
        try{
            return await axios.post(Constants.personalInfo.createPersonalInfoAPI, data);
        }catch(error){
            return error.response;
        }
    },
    updatePersonalInfo: async function(data){
        try{
            return await axios.put(Constants.personalInfo.updatePersonalInfoAPI, data);
        }catch(error){
            return error.response;
        }
    },
}


export default PersonalInfoService;  