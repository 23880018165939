import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Button, CircularProgress, Divider, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { CheckRounded, Close, Error } from '@mui/icons-material';
import './Dialog.style.css';

function MultipleRowsResult({entries, open, onClose}) {
    
    return (
        <Snackbar className='dialog-multiple-row-result-wrapper nq-border nq-background-white' open={open} autoHideDuration={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <div>
                <Button className='nq-icon-action nq-color-xstrong' onClick={onClose}><Close /></Button>
                <List className='dialog-multiple-row-result' >
                {entries && entries.map(entry => {
                    let avatar = entry.status === "pending" ? <CircularProgress style={{width: '20px', height: '20px'}} /> :
                                entry.status === "success" ? <CheckRounded style={{color: 'var(--color_success)'}} /> :
                                entry.status === "failed" ? <Error style={{color: 'var(--color_error)'}}/> : <></>;
                    return <div key={entry.text}><Divider /><ListItem>
                        <ListItemAvatar>
                            {avatar}
                        </ListItemAvatar>
                        <ListItemText primary={entry.text} secondary={entry.additionalText} />
                    </ListItem></div>
                })}
                
            </List>
            </div>
            
        </Snackbar>
    );
}
export default MultipleRowsResult;