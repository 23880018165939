import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { resultToastSelector } from '../../../store/context/ResultToast.context';
import { resultToastDispatch } from '../../../store/context/ResultToast.context';
import { setResultToast } from '../../../store/reducer/ResultToast.slide';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ResultToast() {
    const resultToast = resultToastSelector((state) => state.resultToast.data);
    const dispatch = resultToastDispatch();

    function onCloseCallback(){
        dispatch(setResultToast({resultToast, ...{open: false}}));
    }

    return (
        <Snackbar open={resultToast.open} autoHideDuration={6000}
            onClose={onCloseCallback} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={onCloseCallback} severity={resultToast.severity} sx={{ width: '100%' }}>
                {resultToast.message}
            </Alert>
        </Snackbar>
    );
}
export default ResultToast;