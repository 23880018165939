import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import Loading from "../../../components/common/loading/Loading.index";
import './FileView.style.css';
import FileViewContextMenu from './contextmenu/FileViewContextMenu.index';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FilesService from '../Files.service';
import FileViewItem from './fileviewitem/FileViewItem.index';
import FileViewActionBar from './actionbar/FileViewActionBar.index';
import FileViewConst from './FileViewConst';
import FileViewBreadcrumbs from './breadcrumb/Breadcrumb.index';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MultipleRowsResult from '../../../components/common/dialog/MultipleRowsResult.component';
import Util from '../../../general/Util';
import { resultToastDispatch } from '../../../store/context/ResultToast.context';
import { useWindowSize } from '../../../general/CustomHooks';
import useStayScrolled from 'react-stay-scrolled';

var fileViewScrollTop = 0;

function FileView() {

    const [width, height] = useWindowSize();
    const topHeight = 64 + 48 + 32.8;
    const centerCompHeight = height - topHeight;

    function useQuery() {
        const { search } = useLocation();
      
        return useMemo(() => new URLSearchParams(search), [search]);
      }

    const navigate = useNavigate();

    const resultToastDispatchInstance = resultToastDispatch();

    const [rightClicked, setRightClicked] = useState(false);
    const [clickPoint, setClickPoint] = useState({ x: 0, y: 0 })

    const [openCreateFolderDialog, setOpenCreateFolderDialog] = useState(false);

    const [createFolderInProgress, setCreateFolderInProgress] = useState(false);

    const [openEditFileNameDialog, setOpenEditFileNameDialog] = useState(false);

    const [openDeleteFilesConfirmDialog, setOpenDeleteFilesConfirmDialog] = useState(false);

    const [openUploadResultDialog, setOpenUploadResultDialog] = useState(false);

    const [editFileNameInProgress, setEditFileNameInProgress] = useState(false);

    const [deleteFilesInProgress, setDeleteFilesInProgress] = useState(false);

    const { t } = useTranslation();

    const queryParams = useQuery();

    const [files, setFiles] = useState([]);

    const [selectedFiles, setSelectedFiles] = useState(new Map()); //map of _id -> entry

    const [singleSelectedFileToBeEdited, setSingleSelectedFileToBeEdited] = useState({_id: null, name: null});

    const [uploadingFilesState, setUploadingFilesState] = useState([]);

    const fileViewFilesUploadInput = useRef();

    const [newFolderPayload, setNewFolderPayload] = useState({name: ""});

    const [breadcrumbPath, setBreadcrumbPath] = useState([]);

    const [loading, setLoading] = useState(false);

    const fileViewBoxRef = useRef();

    
    
    const { scroll/*, scrollBottom*/ } = useStayScrolled(fileViewBoxRef);
    useLayoutEffect(() => {
        scroll(fileViewScrollTop);
    }, [selectedFiles])

    function clearNewFolderPayload(){
        setNewFolderPayload({name: ""});
    }

    function clearSingleSelectedFileToBeEdited(){
        setNewFolderPayload({_id: null, name: null});
    }

    async function fetchPersonalFiles(){
        setLoading(true);
        fetchPersonalFilesInSync();
        
    }

    async function fetchPersonalFilesInSync(){
        let fileId = queryParams.get("fileId");
        let rs = await FilesService.getPersonalFiles(fileId ? fileId : null);
        setLoading(false);
        if(rs.status !== 200){
            Util.showResultToast(resultToastDispatchInstance, rs);
        }else{
            if(!rs.data.data || !rs.data.data.path){
                navigate('/files', {replace: true});
            }else{
                setFiles(rs.data.data.entries);
                setBreadcrumbPath(rs.data.data.path);
                setSelectedFiles(new Map());
            }
        }
        
        
    }

    useEffect(() => {
        fetchPersonalFiles();
    }, []);

    useEffect(() => {
        fetchPersonalFiles();
    }, [queryParams]);

    function onAction(actionName) {
        if(actionName === FileViewConst.CREATE_FOLDER_ACTION){
            onNewFolderAction();
        }else if(actionName === FileViewConst.UPLOAD_FILES_ACTION){
            onUploadFilesAction();
        }else if(actionName === FileViewConst.EDIT_FILE_ACTION){
            onEditFileAction();
        }else if(actionName === FileViewConst.MAKE_FILES_PUBLIC_ACTION){
            onMakeFilesPublicAction();
        }else if(actionName === FileViewConst.MAKE_FILES_UNSHARED_ACTION){
            onMakeFilesUnsharedAction();
        }else if(actionName === FileViewConst.DELETE_FILES_ACTION){
            onDeleteFilesAction();
        }
    }

    async function onMakeFilesPublicAction(){
        let result = await FilesService.changeScopeOfFiles(Array.from(selectedFiles.keys()), "public");
        Util.showResultToast(resultToastDispatchInstance, result);
        fetchPersonalFiles();
    }

    async function onMakeFilesUnsharedAction(){
        let result = await FilesService.changeScopeOfFiles(Array.from(selectedFiles.keys()), "not_shared");
        Util.showResultToast(resultToastDispatchInstance, result);
        fetchPersonalFiles();
    }

    function onNewFolderAction(){
        setOpenCreateFolderDialog(true);
    }

    function onUploadFilesAction(){
        fileViewFilesUploadInput.current.click();
    }

    function onDeleteFilesAction(){
        setOpenDeleteFilesConfirmDialog(true);
    }

    function handleDeleteFiles(){
        setDeleteFilesInProgress(true);
        onHandleDeleteFiles();
    }

    async function onHandleDeleteFiles(){
        let ids = Array.from(selectedFiles.keys());
        let result = await FilesService.deleteFiles(ids);
        Util.showResultToast(resultToastDispatchInstance, result);
        setDeleteFilesInProgress(false);
        setOpenDeleteFilesConfirmDialog(false);
        fetchPersonalFiles();
    }

    function triggerUploadFiles(files){
        console.log(files);
        //get parent first
        let parent = null;
        if(queryParams && queryParams.get("fileId")){
            parent = queryParams.get("fileId");
        }
        let fileArray = [...files];
        let uploadingFiles = [];
        for(let file of fileArray){
            let fileSize = Util.getFriendlySize(file.size);
            uploadingFiles.push({text: file.name, additionalText: fileSize, status: "pending"});
        }
        setUploadingFilesState(uploadingFiles);
        setOpenUploadResultDialog(true);
        onTriggerUploadFiles(fileArray, uploadingFiles, parent);
    }

    async function onTriggerUploadFiles(fileArray, uploadingFiles, parent){
        
        while(fileArray.length > 0){
            let aFile = fileArray.shift();
            const data = new FormData();
            
            data.append('files', aFile);
            
            if(parent !== null){
                data.set("parent", parent);
            }
            //data.set("parent", "test parent");
            console.log(data);
            let rs = await FilesService.uploadFiles(data);
            console.log("Finished uploading file " + aFile);
            console.log(rs);
            let status = rs.status !== 201? "failed" : "success";
            updateUploadingFilesState(aFile, status, uploadingFiles);
        }
        
        fetchPersonalFiles();
    }

    async function updateUploadingFilesState(file, status, uploadingFiles){
        let newStates = [];
        for(let oldState of uploadingFiles){
            if(oldState.text === file.name){
                oldState.status = status;
            }
            newStates.push(oldState);
        }
        console.log(newStates);
        setUploadingFilesState(newStates);
    }


    function onEditFileAction(){
        setOpenEditFileNameDialog(true);
    }

    function onRightClickAFile(fileEntry){
        setSingleSelectedFileToBeEdited(fileEntry);
    }

    function onDoubleClickAFolder(file){
        if(file !== null){
            navigate('/files/?fileId=' + file._id);
        }else{
            navigate('/files');
        }
        
    }
    if (loading) {
        return <Loading />;
    } else {
        return (

            <div className="files-main-view">
                {renderActionBar()}
                {renderBreadcrump()}
                {renderFileViewContextmenu()}
                <FilesListView />
                {renderCreateFolderDialog()}
                {renderRenameFileDialog()}
                {renderFilesUploadInput()}
                {renderDeleteFilesConfirmDialog()}
                {renderDeleteFilesResult()}
            </div>);
    }
    

    function renderFileViewContextmenu(){
        return rightClicked && <FileViewContextMenu top={clickPoint.y} left={clickPoint.x} file={null} onAction={onAction} />;
    }

    function renderBreadcrump(){
        return <FileViewBreadcrumbs path={breadcrumbPath} onItemClicked={onDoubleClickAFolder} />;
    }

    function renderActionBar(){
        return <FileViewActionBar selectedFiles={selectedFiles} onAction={onAction} />;
    }

    function handleLeftClickOnMainPage(e){
        setRightClicked(false);
        let classNameOfClickedItem = e.target.className ? e.target.className.toString() : "";
        if(classNameOfClickedItem.includes(FileViewConst.IDENTIFICATON_FILE_VIEW_CONTAINER) ||
        classNameOfClickedItem.includes(FileViewConst.IDENTIFICATON_FILE_VIEW_GRID_VIEW)){
            setSelectedFiles(new Map());
            clearSingleSelectedFileToBeEdited();
        }
        
    }

    function onFileItemClicked(file, e) {
        if (e.detail === 2 && file.isFolder) {
            //double click
            onDoubleClickAFolder(file);
        } else if(e.detail === 1) {
            //if it is a ctrl + select, we should include new selected file into the list
            if (e.ctrlKey !== true) {
                selectedFiles.clear();
            }
            if (selectedFiles.has(file._id)) {
                selectedFiles.delete(file._id);
            } else {
                selectedFiles.set(file._id, file);
            }
            if(selectedFiles.size === 1){
                let [firstElement] = selectedFiles.values();
                setSingleSelectedFileToBeEdited(firstElement);
            }
            setSelectedFiles(new Map(selectedFiles));
        }

    }

    function FilesListView() {
        useEffect(() => {
            
            const handleLeftClick = (e) => handleLeftClickOnMainPage(e);
            window.addEventListener("click", handleLeftClick);
            return () => {
                window.removeEventListener("click", handleLeftClick);
            };
        }, []);

        return (
            <Box className={FileViewConst.IDENTIFICATON_FILE_VIEW_CONTAINER + ' files-view-container'} sx={{ flexGrow: 1, height: centerCompHeight, overflow: 'auto' }}  onContextMenu={(e) => {
                e.preventDefault();
                setRightClicked(true);
                setClickPoint({
                    x: e.pageX,
                    y: e.pageY,
                });
            }} ref={fileViewBoxRef} onScroll={()=> {fileViewScrollTop = fileViewBoxRef.current.scrollTop}}>
                <Grid className={FileViewConst.IDENTIFICATON_FILE_VIEW_GRID_VIEW} container columns={{ xs: 4, sm: 8, md: 12 }}>
                    {files.map((element, index) => (
                        <Grid className={FileViewConst.IDENTIFICATON_FILE_VIEW_GRID_VIEW} item xs={2} sm={4} md={4} key={index}>
                            <FileViewItem className={selectedFiles.has(element._id) ? "files-view-item files-view-item-selected" : "files-view-item"} 
                                fileEntry={element} onClick={(fileEntry, e) => onFileItemClicked(fileEntry, e)} 
                                onContextMenu={(fileEntry) => onRightClickAFile(fileEntry)} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        );
    }

    function onNewFolderNameChange(event){
        setNewFolderPayload({...newFolderPayload, name: event.target.value});
    }
    function renderCreateFolderDialog(){
        return (
            <Dialog open={openCreateFolderDialog} onClose={handleCloseCreateFolderDialog}>
                <DialogTitle>{ t('fileView.newFolderDialog.title')}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="file-view-create-folder-form-name"
                        name="name"
                        label={t('fileView.newFolderDialog.folderName')}
                        type="text"
                        fullWidth
                        variant="standard"
                        required
                        value={newFolderPayload.name}
                        onChange={(event) => onNewFolderNameChange(event)}
                    />
                    
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCreateFolder}>
                    {createFolderInProgress === false ? t('actions.create') : <CircularProgress style={{width: '26px', height: '26px'}} />}
                </Button>
                <Button onClick={handleCloseCreateFolderDialog}>{t('actions.cancel')}</Button>
                </DialogActions>
            </Dialog>
        );
    }

    function onNameInRenameFileDialogChange(event){
        setSingleSelectedFileToBeEdited({...singleSelectedFileToBeEdited, name: event.target.value});
    }

    function renderRenameFileDialog(){
        return (
            <Dialog open={openEditFileNameDialog} onClose={handleCloseEditFileNameDialog}>
                <DialogTitle>{ t('fileView.renameFileDialog.title')}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="file-view-rename-file-form-name"
                        name="name"
                        label={t('fileView.renameFileDialog.folderName')}
                        type="text"
                        fullWidth
                        variant="standard"
                        required
                        value={singleSelectedFileToBeEdited.name}
                        onChange={(event) => onNameInRenameFileDialogChange(event)}
                    />
                    
                </DialogContent>
                <DialogActions>
                <Button onClick={handleRenameFileAction}>
                    {editFileNameInProgress === false ? t('actions.save') : <CircularProgress style={{width: '26px', height: '26px'}} />}
                </Button>
                <Button onClick={handleCloseEditFileNameDialog}>{t('actions.cancel')}</Button>
                </DialogActions>
            </Dialog>
        );
    }

    function renderDeleteFilesConfirmDialog(){
        return (
            <Dialog open={openDeleteFilesConfirmDialog} onClose={handleCloseDeleteFilesConfirmDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle>{t('fileView.deleteDialog.title')}</DialogTitle>
                <DialogContent>
                <DialogContentText id="fileview-delete-alert-dialog-description">
                    {t('fileView.deleteDialog.confirmText')}
                </DialogContentText>
                    
                </DialogContent>
                <DialogActions>
                <Button onClick={handleDeleteFiles}>
                {deleteFilesInProgress === false ? t('actions.confirm') : <CircularProgress style={{width: '26px', height: '26px'}} />}
                </Button>
                <Button onClick={handleCloseDeleteFilesConfirmDialog}>{t('actions.cancel')}</Button>
                </DialogActions>
            </Dialog>
        );
    }

    function renderDeleteFilesResult(){
        return <MultipleRowsResult entries={uploadingFilesState} open={openUploadResultDialog} onClose={() => setOpenUploadResultDialog(false)} />;
    }

    function handleCloseDeleteFilesConfirmDialog(){
        setOpenDeleteFilesConfirmDialog(false);
        setDeleteFilesInProgress(false);
    }

    function renderFilesUploadInput(){
        return <input
            type="file"
            id='file-view-files-upload-input'
            ref={fileViewFilesUploadInput}
            hidden
            multiple
            onChange={(e)=> triggerUploadFiles(e.target.files)} />
    }

    function handleCloseCreateFolderDialog(){
        setOpenCreateFolderDialog(false);
        setCreateFolderInProgress(false);
    }

    function handleCloseEditFileNameDialog(){
        setOpenEditFileNameDialog(false);
        setEditFileNameInProgress(false);
    }

    function handleCreateFolder(){
        setCreateFolderInProgress(true);
        onHandleCreateFolder();
    }
    async function onHandleCreateFolder(){
        let fileId = queryParams.get("fileId");
        let result = await FilesService.createNewFile(newFolderPayload.name, fileId ? fileId : null);
        Util.showResultToast(resultToastDispatchInstance, result);
        setCreateFolderInProgress(false);
        setOpenCreateFolderDialog(false);
        clearNewFolderPayload();
        fetchPersonalFiles();
    }

    function handleRenameFileAction(){
        setEditFileNameInProgress(true);
        onHandleRenameFileAction();
    }
    async function onHandleRenameFileAction(){
        let result = await FilesService.renameFile({_id: singleSelectedFileToBeEdited._id, name: singleSelectedFileToBeEdited.name});
        Util.showResultToast(resultToastDispatchInstance, result);
        setEditFileNameInProgress(false);
        setOpenEditFileNameDialog(false);
        clearSingleSelectedFileToBeEdited();
        fetchPersonalFiles();
    }

}
export default FileView;