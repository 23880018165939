const mint = {
        color_1: "#6b9080",
        color_2: "#a4c3b2",
        color_3: "#cce3de",
        color_4: "#eaf4f4",
        color_5: "#f6fff8",
        color_theme: "#6b9080",
        font_family: "monospace",
        color_public: "#088395",
        color_success: '#66BF3C',
        color_error: '#FF4400'
}
export default mint;