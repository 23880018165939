import './Loading.style.scss';
function Loading() {
    return (
        <div className="nq-loading">
            <div className="nq-loading-dot"></div>
            <div className="nq-loading-dot"></div>
            <div className="nq-loading-dot"></div>
            <div className="nq-loading-dot"></div>
            <div className="nq-loading-dot"></div>
        </div>
    );
}

export default Loading;