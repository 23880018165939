import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';

function CollapsedToolLeftMenu() {
    return (
        <Stack spacing={2} className="lefttmenu lefttmenu-collapsed">

            <Link className='leftmeu-item-link' to="/tools/whiteboard">
                <TipsAndUpdatesOutlinedIcon className="leftmenu-icon" />
            </Link>

            <Link className='leftmeu-item-link' to="/tools/subnetcalculation">
                <LanOutlinedIcon className="leftmenu-icon" />
            </Link>

        </Stack>
    );
}
export default CollapsedToolLeftMenu;