import { DriveFolderUpload, CreateNewFolder, Delete, Edit, LockOpen, Lock, Link } from "@mui/icons-material";
import { Button } from "@mui/material";
import FileViewConst from "../FileViewConst";
import copy from "copy-to-clipboard";
import SimpleToast from "../../../../components/common/dialog/SimpleToast.component";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function FileViewActionBar({selectedFiles, onAction}) {

    const hostname = window.location.origin;

    const [copiedOpen, setCopiedOpen] = useState(false);

    const { t } = useTranslation();


    function onCreateNewFolderButtonClicked(){
        onAction && onAction(FileViewConst.CREATE_FOLDER_ACTION);
    }

    function onUploadFilesButtonClicked(){
        onAction && onAction(FileViewConst.UPLOAD_FILES_ACTION);
    }

    function onEditButtonClicked(){
        onAction && onAction(FileViewConst.EDIT_FILE_ACTION);
    }

    function onDeleteButtonClicked(){
        onAction && onAction(FileViewConst.DELETE_FILES_ACTION);
    }

    function onMakeFilePublicAction(){
        onAction && onAction(FileViewConst.MAKE_FILES_PUBLIC_ACTION);
    }

    function onMakeFileUnsharedAction(){
        onAction && onAction(FileViewConst.MAKE_FILES_UNSHARED_ACTION);
    }

    function onGetPublicLinkAction(){
        let [firstKey] = selectedFiles.keys();
        let url = hostname + FileViewConst.FILE_PUBLIC_PATH + "?fileId=" + firstKey;
        console.log(url);
        copy(url);
        setCopiedOpen(true);
    }

    let classNameOfAction = "nq-padding-left-l nq-padding-right-l nq-color-xstrong";
    let classNameOfDisabledAction = "nq-padding-left-l nq-padding-right-l nq-color-medium";
    let noOfSelectedFiles = selectedFiles ? selectedFiles.size: 0;
    let [firstFile] = selectedFiles.values();

    return (
        <div className="nq-display-flex files-view-action-bar-container">
            <div className="files-view-action-bar nq-background-light nq-display-flex nq-align-items-center">
                {noOfSelectedFiles > 0 && <span>{t("fileView.common.filesSelected", {noOfFiles: noOfSelectedFiles})}</span>}
                
                <Button className={noOfSelectedFiles === 1 ? classNameOfAction : classNameOfDisabledAction} onClick={onEditButtonClicked} disabled={noOfSelectedFiles !== 1}>
                    <Edit fontSize="small" />
                </Button>
                <Button className={noOfSelectedFiles > 0 ? classNameOfAction : classNameOfDisabledAction} onClick={onDeleteButtonClicked} disabled={noOfSelectedFiles <= 0}>
                    <Delete fontSize="small" />
                </Button>
                <Button className={classNameOfAction} onClick={onCreateNewFolderButtonClicked}>
                    <CreateNewFolder fontSize="small" />
                </Button>
                <Button className={classNameOfAction} onClick={onUploadFilesButtonClicked}>
                    <DriveFolderUpload fontSize="small" />
                </Button>
                <Button className={noOfSelectedFiles > 0 ? classNameOfAction : classNameOfDisabledAction} onClick={onMakeFilePublicAction} disabled={noOfSelectedFiles <= 0}>
                    <LockOpen fontSize="small" />
                </Button>
                <Button className={noOfSelectedFiles > 0 ? classNameOfAction : classNameOfDisabledAction} onClick={onMakeFileUnsharedAction} disabled={noOfSelectedFiles <= 0}>
                    <Lock fontSize="small" />
                </Button>
                <Button className={noOfSelectedFiles === 1 && firstFile.accessScope === "public" ? classNameOfAction : classNameOfDisabledAction} onClick={onGetPublicLinkAction} disabled={noOfSelectedFiles !== 1 || firstFile.accessScope !== "public"}>
                    <Link fontSize="small" />
                </Button>
                <SimpleToast open={copiedOpen} onClose={() =>setCopiedOpen(false)} message={t("fileView.common.copiedToClipboard")} />
            </div>
            

        </div>
    );

}
export default FileViewActionBar;