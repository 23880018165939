import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from './translation/i18n';
import { I18nextProvider } from 'react-i18next';
import { CookiesProvider } from "react-cookie";
import { Provider } from 'react-redux';
import store from './store/Global.store';
import ResultToastStore from './store/ResultToast.store';
import { resultToastContext } from './store/context/ResultToast.context';

const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <Provider store={store}>
    <React.StrictMode>
      <CookiesProvider>
        <I18nextProvider i18n={i18n}>
        <Provider store={ResultToastStore} context={resultToastContext}>
          <App />
        </Provider>
          
        
          
        </I18nextProvider>
      </CookiesProvider>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
