import Constants from '../../../general/Constants';
import axios from '../../../general/RESTClient';

const ResourceListService = {
    /**
     * 
     *  [
          {_id: String,
          name: String,
          lookupPath: [String],
          hasContent: Boolean,
          hasSlideSection: Boolean}
        ]
     * 
     */
    getAllResources: async function (){
        try{
            return await axios.get(Constants.resources.getResourcesAPI);
        }catch(error){
            console.log(error);
            return error.response;
        }
    },
    createResource: async function (data) {
        try{
            return await axios.post(Constants.resources.createResourceAPI, data);
        }catch(error){
            return error.response;
        }
    },
    updateResource: async function (data) {
        try{
            return await axios.put(Constants.resources.updateResourceAPI, data);
        }catch(error){
            return error.response;
        }
    },
    deleteResource: async function(id){
        try{
            return await axios.delete(Constants.resources.deleteResourceAPI + "/" + id);
        }catch(error){
            return error.response;
        }
    }
}


export default ResourceListService;  