import Constants from '../../general/Constants';
import axios from '../../general/RESTClient';

const FilesService = {
    
    getPersonalFiles: async function (parentFolderId) {
        try{
            return await axios.get(Constants.files.getPersonalFilesAPI, {params: {parent: parentFolderId}});
        }catch(error){
            return error.response;
        }
    },
    createNewFile: async function (fileName, parentFolderId){
        try {
            return await axios.post(Constants.files.createFileAPI, {isFolder: true, name: fileName, parent: parentFolderId});
        } catch (error) {
            return error.response;
        }
    },
    uploadFiles: async function (formData){
        try{
            return await axios.postForm(Constants.files.uploadFilesAPI, formData);
        }catch(error){
            return error.response;
        }
    },
    changeScopeOfFiles: async function (fileIds, scope){
        try{
            return await axios.put(Constants.files.changeScopeAPI, {fileIds: fileIds, scope: scope});
        }catch(error){
            return error.response;
        }
    },
    renameFile: async function (data){
        try{
            return await axios.put(Constants.files.renameFileAPI, data);
        }catch(error){
            return error.response;
        }
    },
    deleteFiles: async function (ids){
        try{
            return await axios.delete(Constants.files.deleteFilesAPI, {
                data: {ids: ids}
            });
        }catch(error){
            return error.response;
        }
    }
}


export default FilesService;  