const ADMIN_ROLE = "admin";
const USER_ROLE = "user";
const UserRole = {
    hasAdminRole: function(userDetails){
        return userDetails && userDetails.roles && userDetails.roles.includes(ADMIN_ROLE);
    },
    hasUserRole: function(userDetails){
        return userDetails && userDetails.roles && userDetails.roles.includes(USER_ROLE);
    },
    isLoggedinUser: function(userDetails){
        return this.hasAdminRole(userDetails) || this.hasUserRole(userDetails);
    }
}
export default UserRole;