import { useState } from 'react';
import {useWindowSize} from '../../general/CustomHooks';
import './SubjectLayout.style.css';
function CollapsibleSidebarLayout({TopComponent, LargeModeSidebar, SmallModeSidebar, MainComponent}){

    const [width, height] = useWindowSize();
    const [collapse, setCollapse] = useState(false);
    const collapsedSideBarWidth = "3rem";
    const normalSideBarWidth = "18rem";

    const topHeight = 64;
    const centerCompHeight = height - topHeight;

    function getSidebar(){
        if(collapse || width < 900){
            return <SmallModeSidebar />;
        }
        return <LargeModeSidebar />;
    }

    return (<div className="collapsible-layout">
        <div className="collapsible-layout-top nq-theme-shadow" style={{height: topHeight}}>
            <TopComponent />
        </div>
        <div className="collapsible-layout-main nq-theme-shadow" style={{marginTop: topHeight}}>
            
            <div className='collapsible-layout-center' style={{height: centerCompHeight}}>
                <MainComponent />
            </div>
            <div className='collapsible-layout-right nq-theme-shadow' style={{width: collapse || width < 900?collapsedSideBarWidth:normalSideBarWidth}}>
                {getSidebar()}
            </div>
            
        </div>
        
    </div>);
}

export default CollapsibleSidebarLayout;