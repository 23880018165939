const Constants = {
        serverURL: "",
        login:{
            loginAPI: "/api/v1/auth/token",
            logoutAPI: "/api/v1/auth/token"
        },
        user: {
            getUserDetailsAPI: "/api/v1/users/userdetails",
            changePasswordAPI: "/api/v1/users/password"
        },
        category: {
            getAllCatetoriesAPI: "/api/v1/categories",
            createCategoryAPI: "/api/v1/categories",
        },
        resources: {
            getResourcesAPI: "/api/v1/resources",
            createResourceAPI: "/api/v1/resources",
            updateResourceAPI: "/api/v1/resources",
            deleteResourceAPI: "/api/v1/resources",
            addNewSectionAPI: "/api/v1/resources/section",
            updateSectionAPI: "/api/v1/resources/section",
            deleteSectionAPI: "/api/v1/resources"
        },
        resourceContent: {
            getResourceContentByIdAPI: "/api/v1/resourcecontents/query",
            createResourceContentAPI: "/api/v1/resourcecontents",
            updateResourceContentAPI: "/api/v1/resourcecontents",
            deleteResourceContentAPI: "/api/v1/resourcecontents",
        },
        resourceSlide:{
            getSlidesByResourceIdAPI: "/api/v1/slides",
            uploadSlides: "/api/v1/slides/upload",
            viewSlide: "/api/v1/slides/view",
            deleteSlide: "/api/v1/slides"
        },
        personalInfo: {
            getAllPersonalInfoAPI: "/api/v1/personalinfo",
            updatePersonalInfoAPI: "/api/v1/personalinfo",
            createPersonalInfoAPI: "/api/v1/personalinfo",
        },
        files: {
            
            getPersonalFilesAPI: "/api/v1/files",
            createFileAPI: "/api/v1/files",
            uploadFilesAPI: "/api/v1/files/upload",
            changeScopeAPI: "/api/v1/files/scope",
            renameFileAPI: "/api/v1/files/name",
            deleteFilesAPI: "/api/v1/files"
            
        }
}
export default Constants;