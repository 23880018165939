import './TopMenu.style.css';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import LoginService from '../../features/login/Login.service';
import { clearUserDetails, updateGlobalState} from '../../store/reducer/Authorization.slice';
import { useDispatch } from 'react-redux';
import UserRole from '../../general/UserRole';

const pages = [{name: 'menu.resources', link: "/resources"}, {name: 'menu.tools', link: "/tools"}];
const adminPages = [{name: 'menu.files', link: '/files'}];

function TopMenuWidget() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const userDetails = useSelector((state) => state.authorization.userDetails);

  const selectedTopMenuItem = useSelector((state) => state.authorization.globalState.selectedTopMenuItem);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  async function handleLogOut() {
    await LoginService.doLogout();
    dispatch(clearUserDetails());
    navigate('/', { replace: true });

    setAnchorElUser(null);
  }

  function onMenuItemSelected(name){
    dispatch(updateGlobalState({selectedTopMenuItem: name}));
  }

  function renderUserSettingMenu() {
    if (UserRole.hasAdminRole(userDetails)) {
      return (
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton className='user-setting-icon' onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <AccountCircleIcon fontSize='large' />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >

            <MenuItem key="logout" onClick={handleLogOut}>
              <Typography textAlign="center">{t('menu.logout')}</Typography>
            </MenuItem>

            <MenuItem key="changepassword" onClick={handleCloseUserMenu}>
              <Link className='topmenu-item-link-dropdown' to="/admin/changepassword">
                <Typography textAlign="center">{t('menu.changepassword')}</Typography>
              </Link>
            </MenuItem>

          </Menu>
        </Box>
      );
    }
  }

  return (
    <AppBar position="static" className='nq-background-white nq-color-xstrong'>
      <Container maxWidth="xl">
        <Toolbar disableGutters className="topmenu-box">
          <div className='topmenu-logo-wrapper'>
            
            
            <Typography  className='logo-text'
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'SundayPizza',
                fontWeight: 700,
                letterSpacing: '.1rem',
                textDecoration: 'none',
              }}
            >
              <img className='logo logo-large-screen' src='./logo-1-merged-75px.png' alt="Q" />
            </Typography>
          </div>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Link className='topmenu-item-link-dropdown' to={page.link}><Typography textAlign="center">{t(page.name)}</Typography></Link>
                </MenuItem>
              ))}
              {UserRole.hasAdminRole(userDetails) && adminPages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Link className='topmenu-item-link-dropdown' to={page.link}><Typography textAlign="center">{t(page.name)}</Typography></Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          
          <Typography className='logo-text'
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'SundayPizza',
              fontWeight: 700,
              letterSpacing: '.1rem',
              textDecoration: 'none',
            }}
          >
            <img className='logo logo-small-screen' src='./logo-1-merged-75px.png' alt="Q" />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, marginLeft: '2rem' }}>
            {pages.map((page) => (
              <Link key={page.name} className='topmenu-item-link' to={page.link}>
                <Button className={ selectedTopMenuItem === page.name ? 'nq-background-xstrong nq-color-white' : 'nq-button'}
                  
                  onClick={(e) => onMenuItemSelected(page.name)}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {t(page.name)}
                </Button>
              </Link>
            ))}
            {UserRole.hasAdminRole(userDetails) && adminPages.map((page) => (
              <Link key={page.name} className='topmenu-item-link' to={page.link}>
                <Button className={ selectedTopMenuItem === page.name ? 'nq-background-xstrong nq-color-white' : 'nq-button'}
                  onClick={(e) => onMenuItemSelected(page.name)}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {t(page.name)}
                </Button>
              </Link>
            ))}
          </Box>

          {renderUserSettingMenu()}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default TopMenuWidget;