import Constants from '../../../general/Constants';
import axios from '../../../general/RESTClient';

const ResourceContentService = {
    getResourceContentByResourceId: async function (resourceId){
        try{
            return await axios.get(Constants.resourceContent.getResourceContentByIdAPI, 
                {params: {resourceId: resourceId}});
        }catch(error){
            return error.response;
        }
    },
    createResourceContent: async function (data) {
        try{
            return await axios.post(Constants.resourceContent.createResourceContentAPI, data);
            
        }catch(error){
            return error.response;
        }
    },
    updateResourceContent: async function (data){
        try{
            return await axios.put(Constants.resourceContent.updateResourceContentAPI, data);
        }catch(error){
            return error.response;
        }
    }
}


export default ResourceContentService;  