import { useEffect, useState } from "react";
import SlideViewer from "./SlideViewer.component";
import "./SlideViewerDialog.style.css";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {Close, Fullscreen, FullscreenExit} from '@mui/icons-material';
import { Button } from "@mui/material";
import Pagination from '@mui/material/Pagination';
function SlideViewerDialog({onSlideViewerDialogClosed, slides}){

    const [slideViewerInFullScreen, setSlideViewerInFullScreen] = useState(false);

    const [selectedSlideNumber, setSelectedSlideNumber] = useState(1);

    const handleSlideViewerInFullScreenMode = useFullScreenHandle();

    // handle what happens on key press
    function handleKeyPress(event){
        if((event.key === 'ArrowRight' || event.key === 'Enter') && selectedSlideNumber < slides.length){
            setSelectedSlideNumber(selectedSlideNumber + 1);
        }else if(event.key === 'ArrowLeft' && selectedSlideNumber > 1){
            setSelectedSlideNumber(selectedSlideNumber - 1);
        }
    }

    useEffect(() => {
        addKeyPressListener();
        return () => {
          removeKeyPressListener();
        };
      }, [handleKeyPress]);

    function addKeyPressListener() {
        // attach the event listener
        document.addEventListener('keydown', handleKeyPress);
    }

    function removeKeyPressListener(){
        document.removeEventListener('keydown', handleKeyPress);
    }

    function onSlideViewerClosed(){
        handleSlideViewerInFullScreenMode.exit().then(() => {
            
            setSlideViewerInFullScreen(false);
            onSlideViewerDialogClosed();
        });

        removeKeyPressListener();
        
    }

    

    function onSlideViewerInFullScreenAction(){
        
        handleSlideViewerInFullScreenMode.enter();
        setSlideViewerInFullScreen(true);
    }

    function onSlideVieweerInFullScreenExitAction(){
        handleSlideViewerInFullScreenMode.exit();
        setSlideViewerInFullScreen(false);
    }

    function onSlideShowSelectedSlideChange(event, value){
        setSelectedSlideNumber(value);
    }

    function onFullScreenModeChange(state){
        setSlideViewerInFullScreen(state);
    }

    return (
        <FullScreen handle={handleSlideViewerInFullScreenMode} onChange={onFullScreenModeChange} className="slide-viewer-dialog">

            {SlideShowActionBar()}
            <div className='slide-viewer-body'>
                <SlideViewer slide = {slides[selectedSlideNumber-1]} />
            </div>
            {SlideShowControlBar()}
        </FullScreen>
    );

    

    function SlideShowActionBar() {
        return (
            <div className='slide-viewer-action-bar'>
                {slideViewerInFullScreen === false && <Button onClick={onSlideViewerInFullScreenAction}><Fullscreen /></Button>}
                {slideViewerInFullScreen === true && <Button onClick={onSlideVieweerInFullScreenExitAction}><FullscreenExit /></Button>}
                <Button onClick={onSlideViewerClosed}><Close /></Button>
            </div>
        );
    }

    function SlideShowControlBar() {
        return (
            <div className='slide-viewer-control-bar'>
                <Pagination className="slide-viewer-control-bar-pagination" 
                    count={slides.length} page={selectedSlideNumber} color="primary" onChange={onSlideShowSelectedSlideChange} />
            </div>
        );
    }
}

export default SlideViewerDialog;