import { useTranslation } from 'react-i18next';
import HomeLayout from "../../layouts/home/HomeLayout.index";
import TopMenuWidget from "../../widgets/topmenu/TopMenu.index";
import './pagenotexist.style.css';
function PageNotExist(){

    return (
        <HomeLayout TopComponent={TopMenuWidget} MainComponent = {PageNotExistContent}/>
        
    );
}
function PageNotExistContent(){
    const {t} = useTranslation();
    return <div className='nq-theme-shadow page-not-exist'>
        {t('common.pagenotexist')}
    </div>
}
export default PageNotExist;