import { useTranslation } from 'react-i18next';
import {TextField, Box, Button} from '@mui/material';
import {AccountCircle, Key} from '@mui/icons-material';
import validator from 'validator';
import { useState } from 'react';
import LoginService from './Login.service';
import UserService from '../usermanagement/User.service';
import './Login.style.css';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { setUserDetails } from '../../store/reducer/Authorization.slice';

function Login(){
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [emailValid, setEmailValid] = useState({valid: true, init: true});
    const [passwordValid, setPasswordValid] = useState({valid: true, init: true});
    const [credential, setCredential] = useState({email: "", password: ""});
    const [loginState, setLoginState] = useState({loginState: "notSubmit", message: ""});
    const navigate = useNavigate();

    function validateEmail(event){
        setCredential({email: event.target.value, password: credential.password});
        setEmailValid({valid: validator.isEmail(event.target.value), init: false});
    }

    function validatePassword(event){
        setCredential({email: credential.email, password: event.target.value});
        setPasswordValid({valid: !validator.isEmpty(event.target.value), init: false});
    }

    async function handleSubmit(e){
        e.preventDefault();
        
        let rs = await LoginService.doLogin(credential.email, credential.password);
        console.log(rs);
        if(rs && rs.data){
            if(rs.status !== 200){
                setLoginState({loginState: "failed", message: rs.data.errorCode});
            }else{
                let userDetails = await UserService.getUserDetails();
                dispatch(setUserDetails(userDetails.data.data));
                console.log("Going to redirect");
                navigate('/', {replace: true});
            }
        }else{
            setLoginState({loginState: "failed", message: "login.result.failed"});
        }

    };

    return (
    <div className='myapp-login-main nq-margin-auto nq-shadow nq-padding-xl'>
        
        <form onSubmit={handleSubmit}>
            <div className='myapp-login-title'>
                <p className='nq-font-size-l'>{t('login.title')}</p>
            </div>
            <div className='myapp-login-body'>
                <div className='myapp-login-credential'>
                    <div className='myapp-login-credential-group'>
                        <label>{t('login.email')}</label>
                        
                        <Box className='myapp-login-input-box'>
                            <AccountCircle className='input-icon-leading'/>
                            <TextField 
                                error = {!emailValid.valid && !emailValid.init} helperText={ !emailValid.valid && !emailValid.init ? t('validation.emailInvalid') : ""}
                                className='nq-padding-m' id='login-email' variant='outlined' 
                                onChange={validateEmail}/>
                        </Box>
                    </div>
                    <div className='myapp-login-credential-group'>
                        <label>{t('login.password')}</label>
                        <Box className='myapp-login-input-box'>
                            <Key className='input-icon-leading'/>
                            <TextField 
                                error = {!passwordValid.valid && !passwordValid.init} helperText= { !passwordValid.valid && !passwordValid.init ? t('validation.passwordRequired') : ""} 
                                className='nq-padding-m' type={'password'} variant='outlined' id='login-password'
                                onChange={validatePassword} />
                        </Box>
                        
                    </div>
                </div>
                <div className='myapp-login-submit'>
                    <Button variant='contained' 
                        disabled = {!(emailValid.valid && !emailValid.init && passwordValid.valid && !passwordValid.init)}
                        type='submit'>
                            {t('login.submit')}
                    </Button>
                </div>
                <div className='myapp-login-result'>
                    <span className='nq-color-error'>{loginState.loginState && loginState.loginState === "failed" ? t(loginState.message) : ""}</span>
                </div>
            </div>
        </form>
    </div>
    );
}

export default Login;