import CollapsibleSidebarLayout from "../../layouts/subject/CollapsibleSidebarLayout.index";
import TopMenuWidget from "../../widgets/topmenu/TopMenu.index";
import ToolLeftMenu from "./widgets/ToolLeftMenu.index";
import { useParams } from "react-router-dom";
import WhiteBoard from "./widgets/WhiteBoard.index";
import './Tool.style.css';
import CollapsedToolLeftMenu from "./widgets/CollapsedToolLeftMenu.index";
import SubnetCalculation from "./widgets/SubnetCalculation.index";
import { useDispatch } from "react-redux";
import { updateGlobalState } from "../../store/reducer/Authorization.slice";
import { useEffect } from "react";

function Tool() {
    const whiteboard = 'whiteboard';
    const subnetcalculation = 'subnetcalculation';
    const routeParams = useParams();

    const featureName = "menu.tools";

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateGlobalState({selectedTopMenuItem: featureName}));
    }, []);
    
    function getMainComponent(){
        if(routeParams['tool'] === whiteboard){
            return WhiteBoard;
        }else if(routeParams['tool'] === subnetcalculation){
            return SubnetCalculation;
        }
        return WhiteBoard;
    }

    return (

        <CollapsibleSidebarLayout TopComponent={TopMenuWidget} LargeModeSidebar={ToolLeftMenu} SmallModeSidebar={CollapsedToolLeftMenu} MainComponent={getMainComponent()} />


    );
}
export default Tool;