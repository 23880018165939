import './HomeLayout.style.css';

function HomeLayout({ TopComponent, MainComponent }) {
    return (<div className="home-layout">
        <div className="home-layout-top">
            <TopComponent />
        </div>
        <div className="home-layout-main">
            <MainComponent />
        </div>
    </div>);
}
export default HomeLayout;