import { Folder, Article, Public } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import Util from "../../../../general/Util";
import { FileIcon } from 'react-file-icon';

function FileViewItem({fileEntry, onContextMenu, onClick, className}){
    const fileExtension = Util.getFileExtension(fileEntry.name);
    const publicIcon = <Public className="files-view-item-aditional-icon" />;
    const fileIcon = fileEntry.isFolder?
                        <Folder className="files-view-item-icon"></Folder>
                        :<div className="files-view-item-icon">
                            <FileIcon color="var(--color_2)" foldColor="var(--color_3)" glyphColor="var(--color_1)" 
                                    labelColor="var(--color_1)" extension={fileExtension} type={Util.getTypeFromExtension(fileExtension)} />
                                    {fileEntry.accessScope === "public" &&  publicIcon}
                                    </div>
    

    const toolTipContent = <div>
        <div>{fileEntry.name}</div>
        <div>{Util.getFriendlySize(fileEntry.size)}</div>
    </div>;

    return (
        <Tooltip title={toolTipContent}>
            <div className={className ? className : ""} 
            onContextMenu={(e) => {
            onContextMenu && onContextMenu(fileEntry);
            }} 
            onClick={(e) => onClick && onClick(fileEntry, e)}>
            <div className="files-view-item-icon-wrapper nq-text-align-center nq-color-xstrong">
                {fileIcon}
                {fileEntry.isFolder && fileEntry.accessScope === "public" && publicIcon}
            </div>
            <div className="files-view-item-name nq-text-align-center">{fileEntry.name}</div>
        </div>
        </Tooltip>
        
    );
}
export default FileViewItem;