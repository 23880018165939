import { useDispatch } from "react-redux";
import HomeLayout from "../../layouts/home/HomeLayout.index";
import TopMenuWidget from "../../widgets/topmenu/TopMenu.index";
import HomeContent from "./homecontent/HomeContent.index";
import { useEffect } from "react";
import { updateGlobalState } from "../../store/reducer/Authorization.slice";

function Home(){

    const featureName = "home";

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateGlobalState({selectedTopMenuItem: featureName}));
    }, []);

    return (
        <HomeLayout TopComponent={TopMenuWidget} MainComponent = {HomeContent}/>
    );
}
export default Home;