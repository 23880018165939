import Constants from '../../../general/Constants';
import axios from '../../../general/RESTClient';

const ResourceSlideService = {
    getSlidesByResourceId: async function (resourceId){
        try{
            return await axios.get(Constants.resourceSlide.getSlidesByResourceIdAPI, 
                {params: {resourceId: resourceId}});
        }catch(error){
            return error.response;
        }
    },
    uploadSlides: async function (formData){
        try{
            return await axios.postForm(Constants.resourceSlide.uploadSlides, formData);
        }catch(error){
            
            let rs = error.response;
            return rs;
            
        }
    },
    getSlideURLs: function(slide){
        if(slide === null){
            return [];
        }
        let numberOfSlides = slide.numberOfSlides;
        let rs = [];
        for(let index = 0; index < numberOfSlides; index++){
            rs.push(Constants.resourceSlide.viewSlide + "?slideId=" + slide._id + "&slideNumber=" + index);
        }
        return rs;
    },
    deleteSlide: async function(slideId){
        try{
            return await axios.delete(Constants.resourceSlide.deleteSlide, {
                data: {
                    id: slideId
                }
            });
        }catch(error){
            let rs = error.response;
            return rs;
        }
    }
}


export default ResourceSlideService;  