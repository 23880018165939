import { useWindowSize } from '../../general/CustomHooks';
import './SubjectLayout.style.css';

function FixedSidebarLayout({TopComponent, Sidebar, MainComponent, SidebarWith}){

    const [width, height] = useWindowSize();

    const normalSideBarWidth = SidebarWith ? SidebarWith : "18rem";
    const topHeight = 64;
    const centerCompHeight = height - topHeight;

    return (<div className="collapsible-layout">
        <div className="collapsible-layout-top" style={{height: topHeight}}>
            <TopComponent />
        </div>
        <div className="collapsible-layout-main nq-theme-shadow" style={{marginTop: topHeight}}>
            
            <div className='collapsible-layout-center' style={{height: centerCompHeight}}>
                <MainComponent />
            </div>
            <div className='collapsible-layout-right nq-theme-shadow' style={{width: normalSideBarWidth, height: centerCompHeight}}>
                <Sidebar />
            </div>
            
        </div>
        
    </div>);
}

export default FixedSidebarLayout;