import { useState } from 'react';
import { useWindowSize } from '../../general/CustomHooks';
import './ShowHideSidebarLayout.style.css';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { IconButton } from '@mui/material';

function ShowHideSidbarLayout({TopComponent, Sidebar, MainComponent}){

    const maxWidth = 1400;
    const responsiveWidth = 900;

    const [width, height] = useWindowSize();

    const [collapsed, setCollapsed] = useState(false);

    const normalSideBarWidth = 300;

    const sidebarRightPosition = collapsed ? -normalSideBarWidth : 0;

    const centerCompWidth = width < responsiveWidth ? width : width - sidebarRightPosition - normalSideBarWidth;
    
    

    const topHeight = 64;
    const centerCompHeight = height - topHeight;

    function getCenterCompWidth(){
        if(centerCompWidth + normalSideBarWidth > maxWidth && !collapsed){
            return maxWidth - normalSideBarWidth;
        }else{
            return centerCompWidth;
        }
    }
    
    function getLeftOrRightMargin(){
        let plainSize = width - maxWidth;
        if(plainSize <= 0){
            return 0;
        }
        return plainSize/2;
    }

    function getSidebarPosition(){
        if(sidebarRightPosition < 0){
            return sidebarRightPosition;
        }else{
            return sidebarRightPosition + getLeftOrRightMargin();
        }
    }

    function getTogglePosition(){
        return width - getSidebarPosition() - normalSideBarWidth;
    }


    return (<div className="showhide-sidebar-layout">
        <div className="showhide-sidebar-layout-top" style={{height: topHeight}}>
            <TopComponent />
        </div>
        <div className="showhide-sidebar-layout-main" style={{marginTop: topHeight, maxWidth: maxWidth}}>
            
            <div className='showhide-sidebar-layout-center' style={{height: centerCompHeight, width: getCenterCompWidth() - 1, left: getLeftOrRightMargin()}}>
                <MainComponent />
                <IconButton className='showhide-sidebar-layout-toggle' style={{top: topHeight, left: getTogglePosition() - 32}}
                    onClick={() => setCollapsed(!collapsed)}>{collapsed ? <ChevronLeft /> : <ChevronRight />}</IconButton>
            </div>
            <div className='showhide-sidebar-layout-right nq-shadow' 
                    style={{width: normalSideBarWidth, height: centerCompHeight, position: 'fixed', right: getSidebarPosition()}}>
                
                <Sidebar />
                
            </div>
            
        </div>
        
    </div>);
}

export default ShowHideSidbarLayout;