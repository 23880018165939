import { useSelector } from 'react-redux';
import './SlideViewer.style.css';
import UserRole from '../../general/UserRole';

function SlideViewer(props) {
    const {slide} = props;
    let slideUrl = 'url("' + slide + '")';
    const userDetails = useSelector((state) => state.authorization.userDetails);

    const markTemplate = (name) => {
        return `<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='100px' width='100px'><text transform='translate(20, 100) rotate(-45)' fill='rgba(42,42,42,0.4)' font-size='18'>${name}</text></svg>`;
    };

    const base64Mark = btoa(markTemplate("tttquyen.com"));

    return (
        <div className='slide-viewer' style={{backgroundImage: slideUrl}}>
            {!UserRole.hasAdminRole(userDetails) && <div className='slide-viewer-water-mark' style={{backgroundImage: `url("data:image/svg+xml;base64,${base64Mark}")`}}></div>}
        </div>
    );
}

export default SlideViewer;