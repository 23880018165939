import parse from 'html-react-parser';
import './htmlview.style.css';

function HTMLView({htmlText, className}){
    const c = className ? "html-viewer " + className : "html-viewer";

    function parseHTML(){
        try {
            return parse(htmlText);
        } catch (error) {
            return <div>Can not parse the content</div>
        }
    }

    return (
        <div className={c}>{parseHTML()}</div>
    );
}

export default HTMLView;