import {Navigate} from "react-router-dom";
import { useCookies } from "react-cookie";

function AuthCheck({ children }){
    const [cookies] = useCookies();
    if(!cookies.logged_in){
        console.log("Auth Check UnAuthed");
        return <Navigate to = '/login' />;
    }
    return children;
    
}

export default AuthCheck;