import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import './Dialog.style.css';

function SimpleToast({x, y, message, onClose, open}) {
    return (
        <Snackbar className='dialog-simple-toast' open={open} onClose={onClose} autoHideDuration={2000} message={message}>
            
        </Snackbar>
    );
}
export default SimpleToast;