import { useDispatch } from 'react-redux';
import FixedSidebarLayout from '../../layouts/subject/FixedSidebarLayout.index';
import TopMenuWidget from '../../widgets/topmenu/TopMenu.index';
import ExplorerBar from './explorerbar/ExplorerBar.index';
import FileView from './fileview/FileView.index';
import { useEffect } from 'react';
import { updateGlobalState } from '../../store/reducer/Authorization.slice';
import ShowHideSidbarLayout from '../../layouts/subject/ShowHideSidebarLayout.index';

function Files() {

    const featureName = "menu.files";

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateGlobalState({selectedTopMenuItem: featureName}));
    }, []);
    
    return (
        <ShowHideSidbarLayout TopComponent={TopMenuWidget} Sidebar={ExplorerBar} MainComponent={FileView} />
        
    );
}

export default Files;