import { createSlice } from '@reduxjs/toolkit'

export const authorizationSlice = createSlice({
  name: 'authorization',
  initialState: {
    
    userDetails: {
      email: "anonymous@anonymous.com",
      fullName: "Anonymous",
      roles: []
    },
    globalState: {
      selectedTopMenuItem: 0
    }
  },
  reducers: {
    
    setUserDetails: (state, action) => {
      state.userDetails = action.payload
    },
    updateGlobalState: (state, action) => {
      state.globalState = {...state.globalState, ...action.payload}
    },
    clearUserDetails: (state) => {
      state.userDetails = {
        email: "anonymous@anonymous.com",
        fullName: "Anonymous",
        roles: []
      }
    }
  },
});

export const { setUserDetails, clearUserDetails, updateGlobalState } = authorizationSlice.actions;

export default authorizationSlice.reducer;