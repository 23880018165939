import Constants from '../../general/Constants';
import axios from '../../general/RESTClient';

const UserService = {
    getUserDetails: async function () {
        try{
            return await axios.get(Constants.user.getUserDetailsAPI);
        }catch(error){
            return error.response;
        }
    },
    changePassword: async function (oldPassword, newPassword){
        try{
            return await axios.put(Constants.user.changePasswordAPI, {"oldPassword": oldPassword, "newPassword": newPassword});
        }catch(error){
            return error.response;
        }
    }
}


export default UserService;  