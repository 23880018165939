import { useTranslation } from 'react-i18next';
import {TextField, Box, Button} from '@mui/material';
import {Key} from '@mui/icons-material';
import validator from 'validator';
import { useState } from 'react';
import UserService from './User.service';
import '../login/Login.style.css';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';

function ChangePasswordPage(){
    const { t } = useTranslation();
    const [oldPasswordValid, setOldPasswordValid] = useState({valid: true, init: true});
    const [newPasswordValid, setNewPasswordValid] = useState({valid: true, init: true});
    const [data, setData] = useState({oldPassword: "", newPassword: ""});
    const [submitState, setSubmitState] = useState({submitState: "notSubmit", message: ""});
    const navigate = useNavigate();


    function validateOldPassword(event){
        setData({newPassword: data.newPassword, oldPassword: event.target.value});
        setOldPasswordValid({valid: !validator.isEmpty(event.target.value), init: false});
    }

    function validateNewPassword(event){
        setData({newPassword: event.target.value, oldPassword: data.oldPassword});
        setNewPasswordValid({valid: !validator.isEmpty(event.target.value), init: false});
    }

    async function handleSubmit(e){
        e.preventDefault();
        
        let rs = await UserService.changePassword(data.oldPassword, data.newPassword);
        console.log(rs);
        if(rs && rs.data){
            if(rs.status !== 200){
                if(rs.status === 403){
                    setSubmitState({submitState: "failed", message: "changepassword.result.oldpasswordinvalid"});
                }else {
                    setSubmitState({submitState: "failed", message: "changepassword.result.failed.commonerror"});
                }
                
            }else{
                console.log("Going to redirect");
                navigate('/', {replace: true});
            }
        }else{
            setSubmitState({submitState: "failed", message: "changepassword.result.failed.commonerror"});
        }

    }
    function onCancel(){
        navigate('/');
    }

    return (
    <div className='myapp-login-main'>
        
        <form onSubmit={handleSubmit}>
            <div className='myapp-login-title'>
                <p>{t('changepassword.title')}</p>
            </div>
            <div className='myapp-login-body'>
                <div className='myapp-login-credential'>
                    
                    <div className='myapp-login-credential-group'>
                        <label>{t('changepassword.oldpassword')}</label>
                        <Box className='myapp-login-input-box'>
                            <Key className='input-icon-leading'/>
                            <TextField 
                                error = {!oldPasswordValid.valid && !oldPasswordValid.init} helperText= { !oldPasswordValid.valid && !oldPasswordValid.init ? t('validation.passwordRequired') : ""} 
                                className='input-content' type={'password'} variant='outlined' id='changepassword-oldpassword'
                                onChange={validateOldPassword} />
                        </Box>
                        
                    </div>
                    <div className='myapp-login-credential-group'>
                        <label>{t('changepassword.newpassword')}</label>
                        <Box className='myapp-login-input-box'>
                            <Key className='input-icon-leading'/>
                            <TextField 
                                error = {!newPasswordValid.valid && !newPasswordValid.init} helperText= { !newPasswordValid.valid && !newPasswordValid.init ? t('validation.passwordRequired') : ""} 
                                className='input-content' type={'password'} variant='outlined' id='changepassword-newpassword'
                                onChange={validateNewPassword} />
                        </Box>
                        
                    </div>
                </div>
                <div className='myapp-login-submit'>
                    <Button variant='contained' 
                        disabled = {!(oldPasswordValid.valid && !oldPasswordValid.init && newPasswordValid.valid && !newPasswordValid.init)}
                        type='submit'>
                            {t('changepassword.submit')}
                    </Button>
                    <Button variant='contained' onClick={onCancel}>
                            {t('changepassword.cancel')}
                    </Button>
                </div>
                <div className='myapp-login-result'>
                    <span>{submitState.submitState && submitState.submitState === "failed" ? t(submitState.message) : ""}</span>
                </div>
            </div>
        </form>
    </div>
    );
}

export default ChangePasswordPage;