import './HomeContent.style.css';
import { useEffect, useRef, useState } from 'react';
import HTMLView from '../../../components/common/htmlview/htmlview.index';
import TextEditor from '../../../components/common/texteditor/texteditor.index';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import personalinfoService from '../PersonalInfo.service';
import { Button, CircularProgress, IconButton } from '@mui/material';
import { Edit, Check } from '@mui/icons-material';
import Loading from '../../../components/common/loading/Loading.index';
import UserRole from '../../../general/UserRole';


function HomeContent() {
    const PERSONAL_ENTRY = "personalinfo";
    const BANNER_ENTRY = "homebanner";
    const userDetails = useSelector((state) => state.authorization.userDetails);
    const [personalContent, setPersonalContent] = useState('');
    const [personalData, setPersonalData] = useState({content:''});
    const [bannerImgBase64, setBannerImgBase64] = useState(null);
    const [bannerImgData, setBannerImgData] = useState({content:''});
    const [showHTML, setShowHTML] = useState(true);
    const {t} = useTranslation();

    const bannerImgFile = useRef(null) 

    const [bannerSaved, setBannerSaved] = useState(true);

    const [personalInfoLoading, setPersonalInfoLoading] = useState(true);

    const [savingInProgress, setSavingInprogress] = useState(false);

    useEffect(() => {
        
        refresh();
    }, []);

    async function fetchPersonalInfo(){
        let rs = await personalinfoService.getAllPersonalInfo();
        setPersonalInfoLoading(false);
        console.log(rs);
        if(rs.data && rs.data.data){
            let personalInfo = rs.data.data[PERSONAL_ENTRY];
            if(personalInfo){
                setPersonalData(personalInfo);
                setPersonalContent(personalInfo.content);
                console.log(personalData.content);
            }
            let bannerInfo = rs.data.data[BANNER_ENTRY];
            if(bannerInfo){
                setBannerImgData(bannerInfo);
                setBannerImgBase64(bannerInfo.content);
            }
        }
    }

    async function refresh(){
        /*setPersonalInfoLoading(true);
        fetchPersonalInfo();*/
    }

    async function savePersonalInfoContent(){
        setSavingInprogress(true);
        doSavePersonalInfoContent();
    }
    async function doSavePersonalInfoContent(){
        let entry = {
            content: personalContent
        };
        if(personalData._id){
            entry._id = personalData._id;
            entry.name = personalData.name;
            await personalinfoService.updatePersonalInfo(entry);
        }else{
            entry.name = PERSONAL_ENTRY;
            await personalinfoService.createPersonalInfo(entry);
        }
        setSavingInprogress(false);
        setShowHTML(true);
    }

    async function saveBannerImg(){
        let entry = {
            content: bannerImgBase64
        };
        if(bannerImgData._id){
            entry._id = bannerImgData._id;
            entry.name = bannerImgData.name;
            await personalinfoService.updatePersonalInfo(entry);
        }else{
            entry.name = BANNER_ENTRY;
            await personalinfoService.createPersonalInfo(entry);
        }
        setBannerSaved(true);
    }

    function convertToBase64(file) {
        let reader = new FileReader()
    
        reader.readAsDataURL(file)
    
        reader.onload = () => {
          setBannerImgBase64(reader.result)
        }
      }

    function handleBannerImgChange(event){
        let file = event.target.files[0];
        console.log(URL.createObjectURL(file));
        convertToBase64(file);
        setBannerSaved(false);
    }

    function renderAdminActionBarForContent(){
        if(UserRole.hasAdminRole(userDetails) && showHTML){
            return <div className='home-content-center-action-bar'>
            <IconButton onClick={showEdit} className='home-content-center-action-bar-button'><Edit /></IconButton>
        </div>
        }else{
            return <></>;
        }
    }
    function renderEditHTMLActionBar(){
        if(UserRole.hasAdminRole(userDetails) && !showHTML){
            return <div className='home-content-center-edithtml-action-bar'>
            <Button onClick={savePersonalInfoContent} >
                {savingInProgress === false ? t('actions.save') : <CircularProgress style={{width:'26px', height: '26px'}} /> }
            </Button>
            <Button onClick={closeEditHTML} >{t('actions.cancel')}</Button>
        </div>
        }else{
            return <></>;
        }
    }
    async function closeEditHTML(){
        await refresh();
        setShowHTML(true);
    }
    function showEdit(){
        setShowHTML(false);
    }

    function browseBannerImgFile(){
        bannerImgFile.current.click();
    }

    function renderAdminActionBarForBanner(){
        if(UserRole.hasAdminRole(userDetails)){
            return <div className='home-content-center-action-bar'>
            <IconButton onClick={browseBannerImgFile} className='home-content-center-action-bar-button'><Edit /></IconButton>
            {!bannerSaved && <IconButton onClick={saveBannerImg} className='home-content-center-action-bar-button'><Check /></IconButton> }
        </div>
        }else{
            return <></>;
        }
    }

    function getBannerImg(){
        
        return bannerImgBase64;
    }
/*
    return (
        <div className='home-content'>
            {personalInfoLoading ? <Loading /> :
            <><div className="home-content-top nq-shadow">
                {renderAdminActionBarForBanner()}
                <input type='file' id='bannerImgFile' ref={bannerImgFile} style={{display: 'none'}} onChange={handleBannerImgChange}/>
                {getBannerImg() && <img className='home-content-banner' src={getBannerImg()}></img>}
                
            </div>
            <div className="home-content-center nq-shadow">
                {renderAdminActionBarForContent()}
                {showHTML ? renderHTML() : renderTextEditor()}
                {renderEditHTMLActionBar()}
            </div>
            </>
            }
        </div>
    );
    */
   return (
    <div className='home-content'>
    <div className="home-content-center nq-shadow">
        
        <iframe src='portfolio' style={{width: 'calc(100%)', border: 'none'}} ></iframe>
    </div>
        
</div>
    
   );
    function renderTextEditor() {
        return (
            <TextEditor

                text={personalContent}
                onBlur={newContent => setPersonalContent(newContent)} // preferred to use only this option to update the content for performance reasons


            />
        );
    }
    function renderHTML() {
        return <HTMLView htmlText={personalContent} />;
    }
}
export default HomeContent;