import { createSlice } from '@reduxjs/toolkit'

export const resultToastSlice = createSlice({
  name: 'resultToast',
  initialState: {
    data:{
        severity: 'info',
        message: '',
        open: false
    }
    
  },
  reducers: {
    setResultToast: (state, action) => {
      state.data = action.payload
    }
  },
});

export const { setResultToast } = resultToastSlice.actions;

export default resultToastSlice.reducer;