import "./ResourceContent.style.css";
import SlideViewerDialog from "../../../components/slideviewer/SlideViewerDialog.component";
import { useEffect, useRef, useState } from "react";
import { resourceSelector } from '../../../store/context/Resource.context';
import ResourceContentService from "./ResourceContent.service";
import { useTranslation } from 'react-i18next';
import { Attachment, Edit } from "@mui/icons-material";
import HTMLView from "../../../components/common/htmlview/htmlview.index";
import TextEditor from "../../../components/common/texteditor/texteditor.index";
import { useSelector } from 'react-redux';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, Radio, RadioGroup } from "@mui/material";

import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";
import { resultToastDispatch } from '../../../store/context/ResultToast.context';

import Util from "../../../general/Util";
import Loading from "../../../components/common/loading/Loading.index";
import UserRole from "../../../general/UserRole";
import ResourceSlideService from "./ResourceSlide.service";
import MultipleRowsResult from "../../../components/common/dialog/MultipleRowsResult.component";
import AttachmentItem from "./AttachmentItem.index";

function ResourceContent() {

    const CONTENT_TYPE_MD = "MD";
    const CONTENT_TYPE_HTML = "HTML";

    const userDetails = useSelector((state) => state.authorization.userDetails);

    const dispatch = resultToastDispatch();

    const [openSlideViewer, setOpenSlideViewer] = useState(false);
    const [resourceContent, setResourceContent] = useState({ content: "", contentType: null});
    const selectedResource = resourceSelector((state) => state.resource.selectedResource);

    const [internalSelectedResource, setInternalSelectedResource] = useState(null);

    const [resourceContentEditMode, setResourceContentEditMode] = useState(false);

    const [resourceContentLoading, setResourceContentLoading] = useState(false);

    const [savingInProgress, setSavingInprogress] = useState(false);

    const { t } = useTranslation();

    const slidesFilesUploadInput = useRef();

    const [openUploadResultDialog, setOpenUploadResultDialog] = useState(false);
    const [uploadingFilesState, setUploadingFilesState] = useState([]);

    const [slideEntries, setSlideEntries] = useState([]);

    const [selectedSlide, setSelectedSlide] = useState(null);

    const [tobeDeletedSlide, setTobeDeletedSlide] = useState(null);

    const [openDeleteFilesConfirmDialog, setOpenDeleteFilesConfirmDialog] = useState(false);

    const [deleteFilesInProgress, setDeleteFilesInProgress] = useState(false);

    useEffect(() => {
        setResourceContentLoading(true);
        fetchResourceContent();
    }, [internalSelectedResource]);

    useEffect(() => {
        if(selectedResource.hasContent === true){
            setInternalSelectedResource(selectedResource);
        }
    }, [selectedResource]);

    async function fetchResourceContent() {
        
        console.log("Fetching resource content of ", selectedResource);
        if (selectedResource._id !== "") {
            let rs = await ResourceContentService.getResourceContentByResourceId(selectedResource._id);
            if (rs.status === 200) {
                setResourceContent(rs.data.data === null ? {content: "", contentType: null} : rs.data.data);
            } else {
                Util.showResultToast(dispatch, rs);
            }
            //now get slides
            await getSlideEntries();
        }
        setResourceContentLoading(false);
    }

    async function getSlideEntries() {
        let slides = await ResourceSlideService.getSlidesByResourceId(selectedResource._id);
        if (slides.status === 200) {
            setSlideEntries(slides.data.data === null ? [] : slides.data.data);
        } else {
            Util.showResultToast(dispatch, slides);
        }
    }

    async function saveResourceContent(){
        setSavingInprogress(true);
        doSaveResourceContent();
    }

    async function doSaveResourceContent() {
        let rs = null;
        if(resourceContent._id){
            rs = await ResourceContentService.updateResourceContent(resourceContent);
            
        }else{
            let resourceContentWithResourceId = {...{resourceId: selectedResource._id}, ...resourceContent}
            rs = await ResourceContentService.createResourceContent(resourceContentWithResourceId);
        }

        Util.showResultToast(dispatch, rs);
        setSavingInprogress(false);
        setResourceContentEditMode(false);
        fetchResourceContent();
    }

    function updateResourceContent(newContent) {
        let updatedResourceContent = { content: newContent };
        setResourceContent({ ...resourceContent, ...updatedResourceContent });
    }

    function updateResourceContentType(event){
        let updated = {contentType: event.target.value};
        setResourceContent({...resourceContent, ...updated});
    }

    function onOpenSlideViewer(slide) {
        setSelectedSlide(slide);
        setOpenSlideViewer(true);
    }

    function onSlideViewerDialogClosed() {
        setOpenSlideViewer(false);
    }

    function showEditResourceContentForm() {
        setResourceContentEditMode(true);
    }

    function triggerUploadFiles(files){
        console.log(files);
        //get parent first
        let parent = selectedResource._id;
        
        let fileArray = [...files];
        let uploadingFiles = [];
        for(let file of fileArray){
            if(file.name.split('.').pop() === 'pdf'){
                let fileSize = Util.getFriendlySize(file.size);
                uploadingFiles.push({text: file.name, additionalText: fileSize, status: "pending"});
            }
            
        }
        setUploadingFilesState(uploadingFiles);
        setOpenUploadResultDialog(true);
        onTriggerUploadFiles(fileArray, uploadingFiles, parent);
    }

    async function onTriggerUploadFiles(fileArray, uploadingFiles, parent){
        while(fileArray.length > 0){
            let aFile = fileArray.shift();
            const data = new FormData();
            
            data.append('files', aFile);
            
            if(parent !== null){
                data.set("resourceId", parent);
            }
            //data.set("parent", "test parent");
            console.log(data);
            let rs = await ResourceSlideService.uploadSlides(data);
            console.log("Finished uploading file " + aFile);
            console.log(rs);
            let status = rs.status !== 201? "failed" : "success";
            updateUploadingFilesState(aFile, status, uploadingFiles);
        }
        getSlideEntries();
    }

    async function updateUploadingFilesState(file, status, uploadingFiles){
        let newStates = [];
        for(let oldState of uploadingFiles){
            if(oldState.text === file.name){
                oldState.status = status;
            }
            newStates.push(oldState);
        }
        setUploadingFilesState(newStates);
    }

    function handleDeleteSlide(){
        setDeleteFilesInProgress(true);
        onHandleDeleteSlide();
    }

    async function onHandleDeleteSlide(){
        if(tobeDeletedSlide !== null){
            let rs = await ResourceSlideService.deleteSlide(tobeDeletedSlide);
            if (rs.status === 200) {
                getSlideEntries();
            } else {
                Util.showResultToast(dispatch, rs);
            }
        }
        
        setDeleteFilesInProgress(false);
        setOpenDeleteFilesConfirmDialog(false);
    }

    return (
        
            <div className="resource-content nq-padding-m nq-shadow nq-margin-left-m">
            {selectedResource._id != 0 && (resourceContentLoading ? <Loading /> : renderContentSection())}
            {selectedResource._id != 0 && renderSlideSection()}
            {openSlideViewer ?
                <SlideViewerDialog onSlideViewerDialogClosed={onSlideViewerDialogClosed} slides={ResourceSlideService.getSlideURLs(selectedSlide)}  />
                : <></>}
            </div>
        
        
    );

    function renderContentSection() {

        return (

            <div className="resource-content-body">
                {renderAdminActionBarForResourceContent()}
                {!resourceContentEditMode ? renderHTML() : renderTextEditor()}
                {renderEditHTMLActionBar()}
            </div>

        );

    }

    function renderAdminActionBarForResourceContent() {
        if (UserRole.hasAdminRole(userDetails) && !resourceContentEditMode) {
            return <div className='resource-content-action-bar'>
                <IconButton onClick={showEditResourceContentForm} className='resource-content-action-bar-button'><Edit /></IconButton>
            </div>
        } else {
            return <></>;
        }
    }

    function renderTextEditor() {
        let editor = "";
        let isHTMLOption = !resourceContent.contentType || resourceContent.contentType === null || resourceContent.contentType === CONTENT_TYPE_HTML;
        if(isHTMLOption){
            editor =
                <TextEditor
    
                    text={resourceContent.content}
                    onBlur={newContent => updateResourceContent(newContent)} // preferred to use only this option to update the content for performance reasons
    
    
                />
            
        }else{
            editor = 
                <MDEditor 
                    value={resourceContent.content}
                    onChange={newContent => updateResourceContent(newContent)}
                    previewOptions={{
                        rehypePlugins: [[rehypeSanitize]],
                      }}/>
            
        }
        return <div>
            <RadioGroup
                row
                name="contentType-Selection"
                value={resourceContent.contentType}
                onChange={updateResourceContentType}
            >
                <FormControlLabel value={CONTENT_TYPE_HTML} control={<Radio />} label="HTML" />
                <FormControlLabel value={CONTENT_TYPE_MD} control={<Radio />} label="Markdown" />
            </RadioGroup>
            {editor}
        </div>;
    }
    function renderHTML() {
        let isHTMLOption = !resourceContent.contentType || resourceContent.contentType === null || resourceContent.contentType === CONTENT_TYPE_HTML;
        if(isHTMLOption){
            return <HTMLView htmlText={resourceContent.content} />;
        }else{
            return <MDEditor.Markdown source={resourceContent.content} style={{ whiteSpace: 'pre-wrap' }} />
        }
        
        
    }
    function renderEditHTMLActionBar() {
        if (UserRole.hasAdminRole(userDetails) && resourceContentEditMode) {
            return <div className='resource-content-edithtml-action-bar'>
                <Button onClick={saveResourceContent} >
                    {savingInProgress === false ? t('actions.save') : <CircularProgress style={{width: '26px', height: '26px'}} />}
                </Button>
                <Button onClick={closeEditHTML} >{t('actions.cancel')}</Button>
            </div>
        } else {
            return <></>;
        }
    }
    async function closeEditHTML() {
        await fetchResourceContent();
        setResourceContentEditMode(false);
    }

    function onDeleteSlideClicked(entry){
        setTobeDeletedSlide(entry);
        setOpenDeleteFilesConfirmDialog(true);
    }

    function renderSlideSection() {
        if(internalSelectedResource && internalSelectedResource.hasSlideSection){
            return (
                <div className="resource-content-wrapper nq-margin-top-xl nq-margin-bottom-xl">
                    <div className="resource-content-title">
                        <Attachment className="resource-content-icon" onClick={onUploadAction} />
                        {t('resource.resourceContent.slide.title')}
                    </div>
                    <div className="resource-content-slide-body nq-padding-left-xl">
                        {
                            slideEntries.map(entry => {
                                return <AttachmentItem key={entry._id} item={entry} onClick={onOpenSlideViewer} onDelete={() => onDeleteSlideClicked(entry)} />
                            })
                        }
                    </div>
                    {renderFilesUploadInput()}
                    {renderUploadFilesResult()}
                    {renderDeleteFilesConfirmDialog()}
                </div>
            );
        }
        
    }

    function onUploadAction(){
        if(UserRole.hasAdminRole(userDetails)){
            slidesFilesUploadInput.current.click();
        }
        
    }

    function renderFilesUploadInput(){
        return <input
            type="file"
            id='slide-attachment-files-upload-input'
            accept=".pdf"
            ref={slidesFilesUploadInput}
            hidden
            multiple
            onChange={(e)=> triggerUploadFiles(e.target.files)} />
    }

    function renderUploadFilesResult(){
        return <MultipleRowsResult entries={uploadingFilesState} open={openUploadResultDialog} onClose={() => setOpenUploadResultDialog(false)} />;
    }

    function handleCloseDeleteFilesConfirmDialog(){
        setOpenDeleteFilesConfirmDialog(false);
    }

    function renderDeleteFilesConfirmDialog(){
        return (
            <Dialog open={openDeleteFilesConfirmDialog} onClose={handleCloseDeleteFilesConfirmDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle>{t('resource.resourceContent.slide.deleteDialog.title')}</DialogTitle>
                <DialogContent>
                <DialogContentText id="fileview-delete-alert-dialog-description">
                    {t('resource.resourceContent.slide.deleteDialog.confirmText')}
                </DialogContentText>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteSlide}>
                    {deleteFilesInProgress === false ? t('actions.confirm') : <CircularProgress style={{width: '26px', height: '26px'}} />}
                    </Button>
                    <Button onClick={handleCloseDeleteFilesConfirmDialog}>{t('actions.cancel')}</Button>
                </DialogActions>
            </Dialog>
        );
    }


}
export default ResourceContent;