import './App.css';
import './cssthemes/nq-theme.css';
import Login from './features/login/Login.index';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useCookies } from "react-cookie";
import { clearUserDetails, setUserDetails } from './store/reducer/Authorization.slice';
import UserService from './features/usermanagement/User.service';

import { ThemeSelectorContext } from './cssthemes/ThemeSelectorContext';
import { useContext, useEffect } from 'react';
import themes from './cssthemes/Themes';
import Home from './features/home/Home.index';
import AuthCheck from './components/route/AuthCheck';
import PageNotExist from './features/defaultpage/pagenotexist.index';
import Tool from './features/tool/Tool.index';
import ChangePasswordPage from './features/usermanagement/ChangePasswordPage.index';
import Resource from './features/resource/Resource.index';
import Files from './features/files/Files.index';
import ResultToast from './components/common/dialog/ResultToast.component';
import UserRole from './general/UserRole';

function App() {
  const dispatch = useDispatch();
  const [cookies] = useCookies();

  const { themeName, toggleTheme } = useContext(ThemeSelectorContext);

  console.log(themeName);
  const setCSSVariables = theme => {
    for (const value in theme) {
      document.documentElement.style.setProperty(`--${value}`, theme[value]);
    }
  };

  async function fetchUserDetails() {
    //get user details
    console.log("Fetching user details");
    let userDetails = await UserService.getUserDetails();
    console.log(userDetails.data.data);
    if (userDetails.data && userDetails.data.data.email) {
      
      dispatch(setUserDetails(userDetails.data.data));
      if(!UserRole.hasAdminRole(userDetails.data.data)){
        preventDebug();
      }
    }else{
      preventDebug();
    }
  }
  if (cookies.logged_in) {
    fetchUserDetails();
  }else{
    dispatch(clearUserDetails());
    preventDebug();
  }

  useEffect(() => {
    setCSSVariables(themes[themeName]);
  });

  function preventDebug(){
    document.addEventListener('contextmenu', (e) => e.preventDefault());
    document.onkeydown = (e) => {
      // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
      console.log(e.key);
      if(isF12(e) || isCtrlU(e) || isCtrlShiftI(e) || isCtrlShiftJ(e) || isCtrlS(e) || isCtrlC(e)){
        e.preventDefault();
      }
    };
  }

  

function isCtrlU(event){
  let isU = event.ctrlKey && event.key.toLowerCase() === 'u';
  console.log("Is ctrl + U " + isU);
  return isU;
}

function isCtrlShiftI(event){
  let isI = event.ctrlKey && event.shiftKey && event.key.toLowerCase() === 'i';
  console.log("Is ctrl + shift + I " + isI);
  return isI;
}

function isCtrlShiftJ(event){
  let isJ = event.ctrlKey && event.shiftKey && event.key.toLowerCase() === 'j';
  console.log("Is ctrl + shift + J " + isJ);
  return isJ;
}

function isF12(event){
  let isF12 = event.key.toLowerCase() === "f12";
  console.log("F12 " + isF12);
  return isF12;
}

function isCtrlS(event){
  let isCtrls = event.ctrlKey && event.key.toLowerCase() === 's';
  console.log("Is ctrl + S " + isCtrls);
  return isCtrls;
}

function isCtrlC(event){
  let isCtrlC = event.ctrlKey && event.key.toLowerCase() === 'c';
  console.log("Is ctrl + C " + isCtrlC);
  return isCtrlC;
}




  return (

    <div className="App">
      <BrowserRouter>
        <Routes>

          <Route path="/login" element={<Login />} />
          <Route path = '/tools/:tool?' element = {<Tool />} />
          
          <Route path = '/resources' element = {<Resource />} />
          <Route path = '/admin/changepassword' element = {<AuthCheck><ChangePasswordPage /></AuthCheck>} />
          <Route path = '/files' element = {<AuthCheck><Files /> </AuthCheck>} />
          <Route path = '/' element = {<Home />} />
          <Route path = '*' element = {<PageNotExist />} />
        </Routes>
      </BrowserRouter>
      <ResultToast />
    </div>
  );


}

export default App;
