import { createSlice } from '@reduxjs/toolkit'

export const resourceSlice = createSlice({
  name: 'resource',
  initialState: {
    selectedResource: {
        _id: "",
        hasContent: false,
        hasSlideSection: false,
    }
  },
  reducers: {
    setSelectedResource: (state, action) => {
      state.selectedResource = action.payload
    }
  },
});

export const { setSelectedResource } = resourceSlice.actions;

export default resourceSlice.reducer;