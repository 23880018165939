import Constants from '../../general/Constants';
import axios from '../../general/RESTClient';

const LoginService = {
    doLogin: async function (email, password){
        try{
            return await axios.post(Constants.login.loginAPI, {email: email, password: password});
        }catch(error){
            console.log(error);
            return error.response;
        }
    },
    doLogout: async function () {
        try{
            return await axios.delete(Constants.login.logoutAPI);
        }catch(error){
            return error.response;
        }
    }
}


export default LoginService;  