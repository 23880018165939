import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import './Breadcrumb.style.css';
import { Folder } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';


export default function FileViewBreadcrumbs({ path, onItemClicked }) {
    const {t} = useTranslation();
    
    return (
        <div className='file-view-breadcrump nq-border-bottom nq-padding-left-m'>
            <Breadcrumbs aria-label="breadcrumb">
                <div
                    underline="hover"
                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                    color="inherit"
                    onClick={(e) => {onItemClicked && onItemClicked(null)}}
                    
                >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    {t('fileView.breadcrumb.root')}
                </div>
                {path.map((element, index) => {

                    return <div
                    key={element._id}
                    underline="hover"
                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                    color="inherit"
                    onClick={(e) => {onItemClicked && onItemClicked(element)}}
                    
                >
                        <Folder sx={{ mr: 0.5 }} fontSize="inherit" />
                        {element.name}
                    </div>

                })}



            </Breadcrumbs>
        </div>
    );
}