import JoditEditor, { Jodit } from 'jodit-react';
import { useRef } from 'react';

function TextEditor(props){

    const {text, onBlur, onChange} = props;
    const editor = useRef(null);
    return (
        <JoditEditor

                    ref={editor}
                    value={text}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={onBlur} // preferred to use only this option to update the content for performance reasons
                    onChange={onChange}

                />
    );
}

export default TextEditor;