import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';

import { useTranslation } from 'react-i18next';

function ToolLeftMenu() {
    const {t} = useTranslation();
    return (
        <Stack spacing={2} className="lefttmenu">

            <Link className='leftmeu-item-link' to="/tools/whiteboard">
                <TipsAndUpdatesOutlinedIcon className="leftmenu-icon" />
                <Typography variant="button" display="block">
                    {t('tools.whiteboard')}
                </Typography>
            </Link>

        </Stack>
    );
}
export default ToolLeftMenu;