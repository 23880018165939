import React, { useState } from "react";

export const ThemeSelectorContext = React.createContext({
    themeName: "mint",
    toggleTheme: () => {}
  });

  export default ({ children }) => {
    const [themeName, setThemeName] = useState("default");
  
    const toggleTheme = () => {
      themeName === "mint" ? setThemeName("default") : setThemeName("mint");
    };
  
    return (
      <ThemeSelectorContext.Provider value={{ themeName, toggleTheme }}>
        {children}
      </ThemeSelectorContext.Provider>
    );
  };