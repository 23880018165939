import TopMenuWidget from "../../widgets/topmenu/TopMenu.index";
import ResourceContent from "./resourcecontent/ResourceContent.index";
import FixedSidebarLayout from '../../layouts/subject/FixedSidebarLayout.index';
import ResourceList from "./resourcelist/ResourceList.index";
import { Provider } from 'react-redux';
import ResourceStore from "../../store/Resource.store";
import {resourceContext} from "../../store/context/Resource.context";
import { updateGlobalState} from '../../store/reducer/Authorization.slice';
import { useDispatch } from 'react-redux';
import { useEffect } from "react";
import ShowHideSidbarLayout from "../../layouts/subject/ShowHideSidebarLayout.index";

function Resource(){

    const featureName = "menu.resources";

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateGlobalState({selectedTopMenuItem: featureName}));
    }, []);

    return (
        <Provider store={ResourceStore} context={resourceContext}>
            <ShowHideSidbarLayout TopComponent={TopMenuWidget} 
                        MainComponent = {ResourceContent} Sidebar={ResourceList} />
        </Provider>
        
    );
}
export default Resource;