import axios from 'axios';
import Constants from './Constants';

export default axios.create({
    baseURL: Constants.serverURL,
    timeout: 10 * 60 * 1000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'     
    }
});