import { Excalidraw, MainMenu } from "@excalidraw/excalidraw";
import { IconButton } from "@mui/material";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { useState } from "react";

function WhiteBoard(){

  const fullScreenStyle = {position: "absolute", top: 0, left: 0, width: "100%", height: "100%", "z-index": "99"};
  const normalStyle = {display: "contents"};

  const [displayMode, setDisplayMode] = useState(0);

  function makeFullScreen(){
    setDisplayMode(1 - displayMode);
  }

  const renderTopRightUI = () => {
    return (
      <>
        
        <IconButton
          onClick={makeFullScreen}
        >
          {displayMode === 0 ?  <FullscreenIcon /> : <FullscreenExitIcon />}
        </IconButton>
      </>
    );
  };

    const renderMenu = () => {
        return (
          <MainMenu>
            <MainMenu.DefaultItems.LoadScene />
            <MainMenu.DefaultItems.SaveAsImage />
            <MainMenu.DefaultItems.Export />
            <MainMenu.Separator />
            
            {/*<MainMenu.ItemCustom>
              <button
                style={{ height: "2rem" }}
                onClick={() => window.alert("custom menu item")}
              >
                custom item
              </button>
        </MainMenu.ItemCustom>*/}
            <MainMenu.DefaultItems.ToggleTheme />
            <MainMenu.DefaultItems.ChangeCanvasBackground />
            <MainMenu.DefaultItems.Help />
    
          </MainMenu>
        );
      };

    return <div style={displayMode === 0 ? normalStyle : fullScreenStyle}><Excalidraw renderTopRightUI={renderTopRightUI}>{renderMenu()}</Excalidraw></div>;
}

export default WhiteBoard;