import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { CreateNewFolder, DriveFolderUpload, Edit } from '@mui/icons-material';
import './FileViewContextMenu.style.css';
import { useTranslation } from 'react-i18next';
import FileViewConst from '../FileViewConst';

function FileViewContextMenu(props) {
  const { t } = useTranslation();
  const { top, left, file, onAction } = props;
  console.log(file);
  return (
    <div className='files-view-context-menu nq-theme-shadow' style={{ position: 'absolute', top: top + 'px', left: left + 'px' }}>
      {file && file._id && file._id !== null && <nav aria-label="main mailbox folders">
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={(e) => onAction(FileViewConst.EDIT_FILE_ACTION)}>
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText primary={t('fileView.contextMenu.editFile')} />
            </ListItemButton>
          </ListItem>
        </List>
      </nav>}
      <nav aria-label="main mailbox folders">
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={(e) => onAction(FileViewConst.CREATE_FOLDER_ACTION)}>
              <ListItemIcon>
                <CreateNewFolder />
              </ListItemIcon>
              <ListItemText primary={t('fileView.contextMenu.newFolder')} />
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
      <Divider />
      <nav aria-label="secondary mailbox folders">
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={(e) => onAction(FileViewConst.UPLOAD_FILES_ACTION)}>
              <ListItemIcon>
                <DriveFolderUpload />
              </ListItemIcon>
              <ListItemText primary={t('fileView.contextMenu.uploadFiles')} />
            </ListItemButton>
          </ListItem>

        </List>
      </nav>
    </div>
  );
}
export default FileViewContextMenu;