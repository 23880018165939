import { IconButton, Tooltip } from "@mui/material";
import Util from "../../../general/Util";
import { FileIcon } from 'react-file-icon';
import { Delete } from "@mui/icons-material";
import { useSelector } from "react-redux";
import UserRole from "../../../general/UserRole";

function AttachmentItem({item, onClick, onDelete}){
    const userDetails = useSelector((state) => state.authorization.userDetails);

    const fileExtension = Util.getFileExtension(item.name);

    const fileIcon = <div className="resource-content-slide-view-item-icon">
                            <FileIcon color="var(--color_2)" foldColor="var(--color_3)" glyphColor="var(--color_1)" 
                                    labelColor="var(--color_1)" extension={fileExtension} type={Util.getTypeFromExtension(fileExtension)} />
                                    </div>
    

    const toolTipContent = <div>
        <div>{item.name}</div>
        <div>{Util.getFriendlySize(item.size)}</div>
    </div>;

    function onItemClicked(){
        onClick && onClick(item);
    }

    function renderAdminActionsBar(){
        if (UserRole.hasAdminRole(userDetails)) {
            return <div className="resource-content-slide-item-action-bar nq-padding-left-xl">
            <IconButton onClick={onDelete}><Delete fontSize="small" /></IconButton>
        </div>;
        }
    }

    return (
        <div className="resource-content-slide-item nq-margin-top-m nq-border-bottom">
            
                <div className="resource-content-slide-item-icon-wrapper nq-text-align-center nq-color-xstrong">
                    {fileIcon}                
                </div>
                <Tooltip title={toolTipContent}>
                    <div onClick={onItemClicked} className="resource-content-slide-item-name nq-padding-left-xl">{item.name}</div>
                </Tooltip>
                {renderAdminActionsBar()}    
            
        </div>
        
    );
}
export default AttachmentItem;