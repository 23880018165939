const FileViewConst = {
    EDIT_FILE_ACTION :"editFile",
    DELETE_FILES_ACTION: "deleteFile",
    CREATE_FOLDER_ACTION: "createFolder",
    UPLOAD_FILES_ACTION: "uploadFiles",
    MAKE_FILES_PUBLIC_ACTION: "makeFilesPublic",
    MAKE_FILES_UNSHARED_ACTION: "makeFilesUnshared",
    IDENTIFICATON_FILE_VIEW_CONTAINER: "files-view-container-identification",
    IDENTIFICATON_FILE_VIEW_GRID_VIEW: "files-view-grid_view-identification",
    FILE_PUBLIC_PATH: "/api/v1/files/view"
}
export default FileViewConst;