import { Delete, Edit, ExpandMore, ExpandLess, Add, Topic, Check, Article, ArticleOutlined} from "@mui/icons-material";
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import ResourceListService from "./ResourceList.service";

import { resourceDispatch } from '../../../store/context/Resource.context';
import { setSelectedResource } from '../../../store/reducer/Resource.slice';

import { useSelector } from 'react-redux';
import { resourceSelector } from '../../../store/context/Resource.context';

import './ResourceList.style.css';
import { useTranslation } from "react-i18next";

import { resultToastDispatch } from '../../../store/context/ResultToast.context';

import Util from "../../../general/Util";
import Loading from "../../../components/common/loading/Loading.index";
import UserRole from "../../../general/UserRole";

function ResourceList(){

    const resultToastDispatchInstance = resultToastDispatch();

    const [resources, setResources] = useState(new Map());
    const [firstLevelResourceIds, setFirstLevelResourceIds] = useState([]);
    const selectedResource = resourceSelector((state) => state.resource.selectedResource);
    
    const [error, setError] = useState("");
    const [openResourceFormDialogState, setOpenResourceFormDialogState] = useState(false);

    const [openDeleteResourceConfirmDialog, setOpenDeleteResourceConfirmDialog] = useState(false);

    const [focusingResourcePayload, setFocusingResourcePayload] = useState({});
    const [parentOfFocusingResourcePayload, setParentOfFocusingResourcePayload] = useState({});

    const [resourceExpandContext, setResourceExpandContext] = useState({});

    const dispatch = resourceDispatch();

    const userDetails = useSelector((state) => state.authorization.userDetails);

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const [savingInProgress, setSavingInprogress] = useState(false);

    useEffect(() => {
        refresh();
    }, []);

    async function fetchResources(){

        console.log("Fetching resources...");
        let result = await ResourceListService.getAllResources();
        setLoading(false);
        if(result.status !== 200){
            setError("Error when fetching resources list");
            Util.showResultToast(resultToastDispatchInstance, result);
        }else{
            let rootIds = [];
            let resourcesMap = new Map();
            let resourcesFromServer = result.data.data;
            for(const resource of resourcesFromServer){
                if(resource.parent === null){
                    rootIds.push(resource._id);
                }
                resourcesMap.set(resource._id, resource);
            }
            setFirstLevelResourceIds(rootIds);

            setResources(resourcesMap);
            if(rootIds.length > 0){
                let tobeDispatched = resourcesMap.get(rootIds[0]);
                dispatch(setSelectedResource(tobeDispatched));
                expandCollapseAction({_id: tobeDispatched._id, sectionId: tobeDispatched._id});
            }
            
        }
    }

    async function refresh(){
        setLoading(true);
        fetchResources();
    }

    function onItemSelected(element){
        dispatch(setSelectedResource(element));
        expandCollapseAction(element);
    }

    function launchResourceFormDialog(data, parent){
        if(data && data !== null){
            setFocusingResourcePayload(data);
        }
        
        setParentOfFocusingResourcePayload(parent);
        
        setOpenResourceFormDialogState(true);
    }

    function handleCloseResourceFormDialog(){
        setOpenResourceFormDialogState(false);
        clearFocusingResourcePayloadState();
    }

    function launchDeleteResourceConfirmDialog(resource){
        setFocusingResourcePayload(resource);
        setOpenDeleteResourceConfirmDialog(true);
    }

    async function handleCreateOrUpdateResource(){
        setSavingInprogress(true);
        doHandleCreateOrUpdateResource();
    }

    async function doHandleCreateOrUpdateResource(){
        let rs = null;
        if(focusingResourcePayload._id && focusingResourcePayload._id !== ""){
            rs = await ResourceListService.updateResource(focusingResourcePayload);
        }else{
            let payload = {...{}, ...focusingResourcePayload};
            if(parentOfFocusingResourcePayload && parentOfFocusingResourcePayload._id){
                payload = {...payload, ...{parent: parentOfFocusingResourcePayload._id}};
            }
            rs = await ResourceListService.createResource(payload);
        }

        setSavingInprogress(false);
        
        if(rs.status != 201 && rs.status != 200){
            Util.showResultToast(resultToastDispatchInstance, rs);
        }else{
            //reload the list
            await refresh();
            setOpenResourceFormDialogState(false);
            clearFocusingResourcePayloadState();
        }
    }

    function clearFocusingResourcePayloadState(){
        setFocusingResourcePayload({_id: "", name: "", hasContent: false, hasSlideSection: false});
    }

    function fieldOnResourceFormChanged(event){
        let {name, value} = event.target;
        setFocusingResourcePayload((oldPayload) => ({...oldPayload, [name]:value}));
    }

    function fieldCheckboxOnResourceFormChanged(event){
        let {name, checked} = event.target;
        setFocusingResourcePayload((oldPayload) => ({...oldPayload, [name]:checked}));
        
    }

    function renderAddNewResourceAction(){
        if (UserRole.hasAdminRole(userDetails)) {
            return (
                <div className="resource-item-bottom-actions">
                    <Button onClick={() => launchResourceFormDialog(null, null)} className="nq-theme-icon">
                        <Add fontSize="small" />
                    </Button>
                    
                </div>
            );
        }
    }

    function renderActions(element) {
        if (UserRole.hasAdminRole(userDetails)) {
            let className = element._id === selectedResource._id ? "nq-icon-action nq-color-white" : "nq-icon-action nq-color-xstrong";
            return (
                <div className="nq-display-flex">
                    <Button className={className} onClick={() => launchResourceFormDialog(null, element)}>
                        <Add fontSize="small" />
                    </Button>
                    <Button className={className} onClick={() => launchResourceFormDialog(element, null)}>
                        <Edit fontSize="small" />
                    </Button>
                    <Button className={className} onClick={() => launchDeleteResourceConfirmDialog(element)}>
                        <Delete fontSize="small" />
                    </Button>
                    
                </div>
            );
        }
    }

    function expandCollapseAction(resource){
        resourceExpandContext[resource._id] = !resourceExpandContext[resource._id];
        setResourceExpandContext({...resourceExpandContext});
    }

    

    function renderExpandIcon(resource){
        let isExpand = resourceExpandContext[resource._id] === true;
        let icon = isExpand ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />;
        let className = resource._id === selectedResource._id ? "nq-icon-action nq-color-white" : "nq-icon-action nq-color-xstrong";
        return <Button className={className} onClick={() => expandCollapseAction(resource)}>{icon}</Button>;
    }

    function renderResourceIcon(element, noChild){
        if(noChild){
            return <Article fontSize="small" className={element._id === selectedResource._id ? "nq-color-white" : "nq-color-xstrong"} />;
        }
        return <Topic fontSize="small" className={element._id === selectedResource._id ? "nq-color-white" : "nq-color-xstrong"} />;
    }

    function renderResourceList(){
        if(loading){
            return <Loading />;
        }else{
            let rs = renderAll();
            return rs;
        }
    }

    function renderAll() {
        return <div className="nq-height-100 nq-background-white">
            {renderALevel(firstLevelResourceIds, 1)}
            {renderAddNewResourceAction()}
            {renderCreateEditResourceDialog()}
            {renderDeleteResourceConfirmDialog()}
        </div>;
    }

    function renderALevel(listOfResourceIds, level) {

        return <RecursiveComponent listOfResourceIds={listOfResourceIds} level={level} />;
    }

    const RecursiveComponent = ({ parent, listOfResourceIds, level }) => {
        if (!listOfResourceIds || listOfResourceIds.length === 0) {
            return;
        }
        let classNameOfEachItem = "nq-display-flex nq-align-items-center nq-padding-top-l nq-padding-bottom-l nq-padding-right-m";
        let classNameOfEachSelectedItem = classNameOfEachItem + " nq-background-xstrong";
        let classNameOfEachItemText = "nq-cursor-pointer nq-width-100 nq-text-align-left nq-padding-left-m  nq-color-xstrong";
        let classNameOfEachItemSelectedText = "nq-cursor-pointer nq-width-100 nq-text-align-left nq-padding-left-m  nq-color-white";
        let content = listOfResourceIds.map((id) => {
            let currentResource = resources.get(id);
            if(!currentResource){
                return <></>;
            }
            let displayChildrenOrNot = !parent ? "block" : resourceExpandContext[parent._id] ? "block" : "none";
            return (
                <div key={currentResource._id} style={{display: displayChildrenOrNot}} >
                    <div style={{paddingLeft : level + "rem"}} className={currentResource._id === selectedResource._id ?
                        classNameOfEachSelectedItem :
                        classNameOfEachItem}>
                        {renderResourceIcon(currentResource, !currentResource.children || currentResource.children.length === 0)}
                        <div className={currentResource._id === selectedResource._id ? classNameOfEachItemSelectedText : classNameOfEachItemText}
                            onClick={(e) => onItemSelected(currentResource)}>{currentResource.name}</div>

                        {renderActions(currentResource)}
                        {currentResource.children && currentResource.children.length > 0 && renderExpandIcon(currentResource)}

                    </div>
                    {currentResource.children && <RecursiveComponent parent={currentResource} listOfResourceIds={currentResource.children} level={level+1} />}
                </div>

            );
        });
        return content;
    };

    function renderCreateEditResourceDialog(){
        return (
            <Dialog open={openResourceFormDialogState} onClose={handleCloseResourceFormDialog}>
                <DialogTitle>{focusingResourcePayload._id !== "" ? t('resource.resourceList.editDialog.title') : t('resource.resourceList.createDialog.title')}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="resource-creation-form-resource-name"
                        name="name"
                        label={t('resource.resourceList.createDialog.resourcename')}
                        type="text"
                        fullWidth
                        variant="standard"
                        required
                        value={focusingResourcePayload.name}
                        onChange={fieldOnResourceFormChanged}
                    />
                    <FormControlLabel control={
                        <Checkbox
                        id="resource-creation-form-resource-hasContent"
                        name="hasContent"
                        value={focusingResourcePayload.hasContent}
                        checked={focusingResourcePayload.hasContent}
                        onChange={fieldCheckboxOnResourceFormChanged} />
                        } 
                        label={t('resource.resourceList.createDialog.resourcehascontent')}
                    />

                    <FormControlLabel control={
                        <Checkbox
                        id="resource-creation-form-resource-hasSlideSection"
                        name="hasSlideSection"
                        value={focusingResourcePayload.hasSlideSection}
                        checked={focusingResourcePayload.hasSlideSection}
                        onChange={fieldCheckboxOnResourceFormChanged} />
                        } 
                        label={t('resource.resourceList.createDialog.resourcehasslidesection')}
                    />
                    
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCreateOrUpdateResource}>
                {savingInProgress === false ? t('actions.save') : <CircularProgress style={{width: '26px', height: '26px'}} />}
                </Button>
                <Button onClick={handleCloseResourceFormDialog}>{t('actions.cancel')}</Button>
                </DialogActions>
            </Dialog>
        );
    }

    function handleCloseDeleteResourceConfirmDialog(){
        setOpenDeleteResourceConfirmDialog(false);
        clearFocusingResourcePayloadState();
    }

    async function handleDeleteResource(){
        setSavingInprogress(true);
        doHandleDeleteResource();
    }

    async function doHandleDeleteResource(){
        let rs = await ResourceListService.deleteResource(focusingResourcePayload._id);
        setSavingInprogress(false);
        if(rs.status !== 200){
            Util.showResultToast(resultToastDispatchInstance, rs);
        }else{
            setOpenDeleteResourceConfirmDialog(false);
            //reload data
            refresh();
        }
    }

    function renderDeleteResourceConfirmDialog(){
        return (
            <Dialog open={openDeleteResourceConfirmDialog} onClose={handleCloseDeleteResourceConfirmDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle>{t('resource.resourceList.deleteDialog.title')}</DialogTitle>
                <DialogContent>
                <DialogContentText id="resource-delete-alert-dialog-description">
                    {t('resource.resourceList.deleteDialog.confirmText', {'resourceName' : focusingResourcePayload.name})}
                </DialogContentText>
                    
                </DialogContent>
                <DialogActions>
                <Button onClick={handleDeleteResource}>
                {savingInProgress === false ? t('actions.confirm') : <CircularProgress style={{width: '26px', height: '26px'}} />}
                </Button>
                <Button onClick={handleCloseDeleteResourceConfirmDialog}>{t('actions.cancel')}</Button>
                </DialogActions>
            </Dialog>
        );
    }
    return renderResourceList();
}

export default ResourceList;